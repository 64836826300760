import TitleButtons from "../../../components/MainLayout/TitleButtons";
import { useAuthState } from "../../../contexts/authContext";
import { useReportState } from "../../../contexts/reportContext";
import ReportTableList from "./ReportTableList";

const ReportsList = () => {
    const { user } = useAuthState();
    const { reports } = useReportState();
    const columns = [
        { title: "", data: "property", width: 80 },
        { title: "Ref No", data: "referenceNo" },
        { title: "Date", data: "dateOfReport" },
        { title: "Address", data: "address" },
        { title: "Town", data: "town" },
        { title: "Postcode", data: "postcode" },
        { title: "Customer", data: "customerName" },
        { title: "Report type", data: "reportType" },
        { title: "Inspector", data: "completedBy" },
        { title: "Tenants", data: "tenancyCount" },
        { title: "Status", data: "status", width: 140 },
        { title: "", data: "viewReport" },
    ];

    const data = reports?.map((report) => {
        return {
            ...report,
            referenceNo: report.referenceNo.slice(0, 20),
            reportType: (report.reportType === "Checkout Defects") ? report.reportType : report.reportType.split(" ")[0],
        }
    });


    return (
        <div className="pb-1">
            <TitleButtons title={"reports"} userRole={user?.role} addButton={"Report"} />
            <div className="hidden md:block border border-[#eeeeee] rounded-lg shadow-lg pt-10 mb-20">
                <ReportTableList columns={columns} data={data || []} />

            </div>
        </div>
    )
}
export default ReportsList;