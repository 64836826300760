import React, { useMemo, useEffect, useState, useCallback } from "react";
import {
    useReactTable,
    getCoreRowModel,
    getPaginationRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    flexRender,
} from "@tanstack/react-table";
import { matchSorter } from "match-sorter";
import "./ReportTableList.css";
import { useNavigate } from "react-router-dom";
import { COLOR_SCHEMES } from "../../../utils/constants";
import DropdownMenuPortal from "../../../components/DropdownMenu";

import { ApartmentRounded, DownloadOutlined, EditOutlined, FilterAltOutlined, FilterOutlined, HolidayVillageOutlined, InsertPhotoOutlined, PreviewOutlined, VisibilityOutlined } from "@mui/icons-material";
const ALL_REPORT_TYPES = [
    "Inventory",
    "Checkout",
    "Checkout Defects",
    "Inspection",
];
const ALL_STATUSES = [
    "draft",
    "completed",
    "awaiting",
    "signed",
    "pending",
    "feedback"
];
// e.g. in ReportTableList.jsx or a separate file
function multiValueFilter(row, columnId, filterValues) {
    // If user hasn't chosen any types, don't filter those rows out
    if (!filterValues || filterValues.length === 0) {
        return true;
    }
    const rowValue = row.getValue(columnId);
    return filterValues.includes(rowValue);
}


const fuzzyFilter = (row, columnId, value) => {
    return matchSorter([row.getValue(columnId)], value).length > 0;
};

const STORAGE_KEY = 'reportTableState';

const ReportTableList = ({ data, columns }) => {
    // Get any saved state from localStorage
    const getSavedState = useCallback(() => {
        try {
            const savedState = localStorage.getItem(STORAGE_KEY);
            if (savedState) {
                return JSON.parse(savedState);
            }
        } catch (error) {
            console.error('Error loading saved table state:', error);
        }
        return null;
    }, []);

    const savedState = getSavedState();

    const [pagination, setPagination] = useState(savedState?.pagination || {
        pageIndex: 0,
        pageSize: 10,
    });

    const [sorting, setSorting] = useState(savedState?.sorting || []);
    const [globalFilter, setGlobalFilter] = useState(savedState?.globalFilter || "");
    const [searchValue, setSearchValue] = useState(savedState?.searchValue || "");
    const navigate = useNavigate();
    const [isReportDropdownOpen, setIsReportDropdownOpen] = useState(false);
    const [isReportStatusDropdownOpen, setIsReportStatusDropdownOpen] = useState(false);

    // Store the column filter state with saved values if available
    const [columnFilters, setColumnFilters] = useState(savedState?.columnFilters || []);
    // Store the user's selected types with saved values if available
    const [selectedReportTypes, setSelectedReportTypes] = useState(savedState?.selectedReportTypes || []);
    const [selectedReportStatuses, setSelectedReportStatuses] = useState(savedState?.selectedReportStatuses || []);

    // Handle preview popup
    const [popupPreviewOpen, setPopupPreviewOpen] = useState(false);

    // Save state to localStorage
    const saveState = useCallback(() => {
        try {
            const stateToSave = {
                pagination,
                sorting,
                globalFilter,
                searchValue,
                columnFilters,
                selectedReportTypes,
                selectedReportStatuses
            };
            localStorage.setItem(STORAGE_KEY, JSON.stringify(stateToSave));
        } catch (error) {
            console.error('Error saving table state:', error);
        }
    }, [pagination, sorting, globalFilter, searchValue, columnFilters, selectedReportTypes, selectedReportStatuses]);

    // Save state whenever these values change
    useEffect(() => {
        saveState();
    }, [pagination, sorting, globalFilter, searchValue, columnFilters, selectedReportTypes, selectedReportStatuses, saveState]);

    function handleReportTypeToggle(type) {
        setSelectedReportTypes((prev) => {
            if (prev.includes(type)) {
                // If already in array, remove it
                return prev.filter((t) => t !== type);
            } else {
                // Otherwise add it
                return [...prev, type];
            }
        });
    }

    function handleReportStatusToggle(type) {
        setSelectedReportStatuses((prev) => {
            if (prev.includes(type)) {
                // If already in array, remove it
                return prev.filter((t) => t !== type);
            } else {
                // Otherwise add it
                return [...prev, type];
            }
        });
    }


    useEffect(() => {
        const timeout = setTimeout(() => {
            setGlobalFilter(searchValue);
        }, 300);
        return () => clearTimeout(timeout);
    }, [searchValue]);


    // Whenever `selectedReportTypes` changes, update our column filter for 'reportType'.
    useEffect(() => {
        // Remove any old filter for reportType, then add the new one:
        setColumnFilters((oldFilters) => {
            // “Reset” any filter for 'reportType' before we add our new one
            const filtered = oldFilters.filter((f) => f.id !== "reportType");
            return [
                ...filtered,
                { id: "reportType", value: selectedReportTypes },
            ];
        });
    }, [selectedReportTypes]);

    useEffect(() => {
        // Remove any old filter for reportType, then add the new one:
        setColumnFilters((oldFilters) => {
            // “Reset” any filter for 'reportType' before we add our new one
            const filtered = oldFilters.filter((f) => f.id !== "status");
            return [
                ...filtered,
                { id: "status", value: selectedReportStatuses },
            ];
        });
    }, [selectedReportStatuses]);


    const getFilterFnForColumn = (columnName) => {
        switch (columnName) {
            case "reportType":
                return multiValueFilter;
            case "status":
                return multiValueFilter;
            default:
                return null;
        }
    }


    const columnDefs = useMemo(() => {
        return columns.map((col) => ({
            accessorKey: col.data,
            header: col.title,
            enableSorting: [
                "referenceNo",
                "dateOfReport",
                "address",
                "town",
                "postcode",
            ].includes(col.data),


            size: col.width ?? undefined,
            filterFn: getFilterFnForColumn(col.data),
            cell: (info) => {
                const value = info.getValue();

                if (col.data === "status") {
                    const background = COLOR_SCHEMES[value]?.bg || "bg-gray-200";
                    const textColor = COLOR_SCHEMES[value]?.text || "text-gray-800";
                    return (
                        <div
                            style={{
                                backgroundColor: background,
                                color: textColor,
                                padding: "2px",
                                fontWeight: 600,
                                borderRadius: "7px",
                                border: `1px solid ${textColor}`,

                            }}
                            className={`flex items-center justify-center`}>
                            {value === "waiting_to_be_signed" ? "waiting" : value}
                        </div>
                    );
                }

                if (col.data === "property") {
                    const cdnBase = "https://d3vkxaojzuhm56.cloudfront.net";
                    const s3Base = "https://ivyproject.s3.eu-central-1.amazonaws.com";

                    let relativePath = value.replace(s3Base, "");
                    relativePath = decodeURIComponent(relativePath);
                    relativePath = encodeURI(relativePath);

                    const imageUrl = `${cdnBase}${relativePath}?width=60&height=60`;

                    if (!value) {
                        return (
                            <ApartmentRounded />
                        );
                    }
                    return (
                        <img
                            src={imageUrl}
                            alt="property"
                            loading="lazy"
                            width={60}
                            height={60}
                            className="border border-[#eeeeee] rounded-md bg-black"
                            style={{ objectFit: "cover" }}
                        />
                    );
                }

                if (col.data === "viewReport") {
                    if (value && typeof value === "object" && value.route) {
                        return <DropdownMenuPortal
                            options={[
                                {
                                    label: "View Report",
                                    icon: VisibilityOutlined, // Or use lucide icons
                                    onClick: () => {
                                        console.log(`handleView`);
                                        navigate(value.route, {
                                            state: { item: value.item, isEdit: false },
                                        });
                                    },
                                },
                                {
                                    label: "Edit",
                                    icon: EditOutlined, // Or use lucide icons
                                    onClick: () => {
                                        console.log(`handleEdit`);
                                        navigate(`/${value.route.split("/")[1]}/edit`, {
                                            state: { item: value.item, isEdit: true },
                                        });
                                    },
                                },
                                {
                                    label: "View Tenancy",
                                    icon: HolidayVillageOutlined, // Or use lucide icons
                                    onClick: () => {
                                        alert('TODO');

                                    },
                                },
                                {
                                    label: "Preview",
                                    icon: PreviewOutlined, // Or use lucide icons
                                    onClick: () => {
                                        window.open(
                                            `/view-pdf/${value?.item?._id}`,
                                            "_blank"
                                        );

                                    },
                                },
                                {
                                    label: "Download",
                                    icon: DownloadOutlined, // Or use lucide icons
                                    onClick: () => {
                                        alert('TODO');


                                    },
                                },
                                {
                                    label: "Gallery",
                                    icon: InsertPhotoOutlined, // Or use lucide icons
                                    onClick: () => {
                                        navigate(`/reports/gallery/${value.item?._id}`);

                                    },
                                },
                            ]}
                        />

                    }
                    return null;
                }

                if (col.data === "dateOfReport" && value) {
                    return new Date(value).toLocaleDateString();
                }

                return value != null ? String(value) : "";
            },
        }));
    }, [columns]);

    const table = useReactTable({
        data,
        columns: columnDefs,
        state: {
            pagination,
            globalFilter,
            sorting,
            columnFilters
        },
        filterFns: {
            fuzzy: fuzzyFilter,
            multiValueFilter: multiValueFilter, // name it whatever you like
        },
        onColumnFiltersChange: setColumnFilters,

        onPaginationChange: setPagination,
        onGlobalFilterChange: setGlobalFilter,
        onSortingChange: setSorting,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        /**
         * If you want to leverage the column's `size`, `minSize`, or `maxSize`,
         * you'll also need enableColumnResizing: true and set a resize mode, e.g.:
         *
         * enableColumnResizing: true,
         * columnResizeMode: "onChange" // or "onEnd"
         *
         * That said, if you *only* need to lock in widths so your table doesn't jitter,
         * simply using table-layout: fixed and .w-xx classes will suffice.
         */
    });

    const currentPageIndex = table.getState().pagination.pageIndex;
    const totalPages = table.getPageCount();

    const pageNumbers = useMemo(
        () => getPaginationRange(currentPageIndex, totalPages, 2),
        [currentPageIndex, totalPages]
    );

    return (
        <div className="overflow-visible relative">
            <div className="mr-4 ml-4 mb-4 flex items-center justify-between gap-4">
                {/* New button to clear filters and reset table state */}
                <button
                    onClick={() => {
                        // Clear all filters and reset state
                        setSelectedReportTypes([]);
                        setSelectedReportStatuses([]);
                        setSearchValue("");
                        setGlobalFilter("");
                        setPagination({
                            pageIndex: 0,
                            pageSize: 10,
                        });
                        setSorting([]);
                        // Clear from localStorage
                        localStorage.removeItem(STORAGE_KEY);
                    }}
                    className="text-coolBlue bg-gray-200 text-sm font-medium py-2 px-3 rounded-md"
                >
                    Reset Filters
                </button>

                <div className="flex items-center gap-4">
                    {/* The "Report Type" button with dropdown */}
                    <div className="relative inline-block">
                        <button
                            onClick={() => setIsReportDropdownOpen(!isReportDropdownOpen)}
                            className="min-w-[200px] text-coolBlue bg-gray-200 text-sm font-medium py-2 px-3 rounded-md inline-flex items-center"
                        >
                            <FilterAltOutlined className="mr-1" />
                            <span>Report Type</span>
                            {selectedReportTypes.length > 0 && (
                                <span className="ml-2 bg-indigo-600 text-white text-xs font-semibold rounded-full h-5 w-5 flex items-center justify-center">
                                    {selectedReportTypes.length}
                                </span>
                            )}
                        </button>

                        {isReportDropdownOpen && (
                            <div className="absolute z-50 mt-2 w-56 bg-white border border-gray-200 rounded shadow-md py-2">
                                {ALL_REPORT_TYPES.map((type) => {
                                    const isSelected = selectedReportTypes.includes(type);
                                    return (
                                        <label
                                            key={type}
                                            className="flex items-center px-3 py-1 hover:bg-gray-100 cursor-pointer font-small"
                                        >
                                            <input
                                                type="checkbox"
                                                checked={isSelected}
                                                className="mr-2 form-checkbox h-5 w-5 text-blue-600 rounded transition duration-150 ease-in-out"
                                                onChange={() => handleReportTypeToggle(type)}
                                            />
                                            <span>{type}</span>
                                        </label>
                                    );
                                })}
                            </div>
                        )}
                    </div>

                    {/* The "Report Status" button with dropdown */}
                    <div className="relative inline-block">
                        <button
                            onClick={() => setIsReportStatusDropdownOpen(!isReportStatusDropdownOpen)}
                            className="min-w-[200px] text-coolBlue bg-gray-200 text-sm font-medium py-2 px-3 rounded-md inline-flex items-center"
                        >
                            <FilterAltOutlined className="mr-1" />
                            <span>Report Status</span>
                            {selectedReportStatuses.length > 0 && (
                                <span className="ml-2 bg-indigo-600 text-white text-xs font-semibold rounded-full h-5 w-5 flex items-center justify-center">
                                    {selectedReportStatuses.length}
                                </span>
                            )}
                        </button>

                        {isReportStatusDropdownOpen && (
                            <div className="absolute z-50 mt-2 w-56 bg-white border border-gray-200 rounded shadow-md py-2">
                                {ALL_STATUSES.map((type) => {
                                    const isSelected = selectedReportStatuses.includes(type);
                                    return (
                                        <label
                                            key={type}
                                            className="flex items-center px-3 py-1 hover:bg-gray-100 cursor-pointer font-small"
                                        >
                                            <input
                                                type="checkbox"
                                                checked={isSelected}
                                                className="mr-2 form-checkbox h-5 w-5 text-blue-600 rounded transition duration-150 ease-in-out"
                                                onChange={() => handleReportStatusToggle(type)}
                                            />
                                            <span>{type}</span>
                                        </label>
                                    );
                                })}
                            </div>
                        )}
                    </div>

                    {/* Global Search Box */}
                    <input
                        type="text"
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        placeholder="Search..."
                        className="min-w-[300px] border p-2 rounded-md w-full max-w-sm"
                    />
                </div>
            </div>


            <table
                className="align tenstackTable table-fixed w-full border-collapse border border-gray-300"
            >
                <thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                const canSort = header.column.getCanSort();
                                const isSorted = header.column.getIsSorted();
                                const columnSize = header.column.columnDef.size;

                                return (
                                    <th
                                        key={header.id}
                                        // Example of inline styling from column size:
                                        style={{
                                            width: columnSize ? columnSize : "auto",
                                        }}
                                        className={`px-4 py-2 text-left ${canSort ? "cursor-pointer" : ""
                                            }`}
                                        onClick={canSort ? header.column.getToggleSortingHandler() : undefined}
                                    >
                                        {flexRender(header.column.columnDef.header, header.getContext())}
                                        {isSorted ? (isSorted === "asc" ? " 🔼" : " 🔽") : ""}
                                    </th>
                                );
                            })}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {table.getRowModel().rows.map((row) => (
                        <tr key={row.id} className="hover:bg-gray-100">
                            {row.getVisibleCells().map((cell) => (
                                <td key={cell.id} className="px-4 py-2 border-b-2 border-gray-200 truncate relative">
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* --- PAGINATION --- */}
            <div className="flex items-center justify-center mt-5 mb-5">
                <span class="total_info">
                    Showing {table.getRowModel().rows.length === 0 ? 0 : table.getState().pagination.pageIndex * table.getState().pagination.pageSize + 1}
                    {" "}
                    to{" "}
                    {Math.min(
                        (table.getState().pagination.pageIndex + 1) * table.getState().pagination.pageSize,
                        table.getFilteredRowModel().rows.length
                    )}{" "}
                    of {table.getFilteredRowModel().rows.length} entries
                </span>

            </div>
            <div
                className="dataTables_paginate paging_simple_numbers flex items-center gap-2 mt-4 justify-end mb-4 mr-5"
                id="table_paginate"
            >
                {/* PREVIOUS button */}
                <a
                    className={
                        "paginate_button previous px-3 py-1 " +
                        (table.getCanPreviousPage() ? "cursor-pointer" : "disabled opacity-50")
                    }
                    onClick={() => {
                        if (table.getCanPreviousPage()) {
                            table.previousPage();
                        }
                    }}
                    aria-disabled={!table.getCanPreviousPage()}
                    aria-role="link"
                    data-dt-idx="previous"
                    tabIndex={-1}
                    id="table_previous"
                >
                    Previous
                </a>

                {/* PAGE NUMBER buttons */}
                <span className="flex items-center gap-1">
                    {pageNumbers.map((item, idx) => {
                        if (item === "...") {
                            return (
                                <span key={idx} className="ellipsis px-2 select-none">
                                    …
                                </span>
                            );
                        }
                        const pageNum = item; // 1-based
                        const isActive = pageNum === currentPageIndex + 1;
                        return (
                            <a
                                key={idx}
                                className={
                                    "paginate_button px-3 py-1 border rounded " +
                                    (isActive ? "current bg-indigo-700 text-white" : "hover:bg-gray-100 cursor-pointer text-[#767676]")
                                }
                                onClick={() => table.setPageIndex(pageNum - 1)}
                                aria-role="link"
                                aria-current={isActive ? "page" : undefined}
                                data-dt-idx={pageNum - 1}
                            >
                                {pageNum}
                            </a>
                        );
                    })}
                </span>

                {/* NEXT button */}
                <a
                    className={
                        "paginate_button next px-3 py-1 " +
                        (table.getCanNextPage() ? "cursor-pointer" : "disabled opacity-50")
                    }
                    onClick={() => {
                        if (table.getCanNextPage()) {
                            table.nextPage();
                        }
                    }}
                    aria-disabled={!table.getCanNextPage()}
                    aria-role="link"
                    data-dt-idx="next"
                    tabIndex={-1}
                    id="table_next"
                >
                    Next
                </a>
            </div>
        </div>
    );
};

export default React.memo(ReportTableList);

function getPaginationRange(currentPageIndex, totalPages, delta = 2) {
    const current = currentPageIndex + 1; // convert 0-based to 1-based
    if (totalPages <= 7) {
        return Array.from({ length: totalPages }, (_, i) => i + 1);
    }

    const range = [];
    const left = Math.max(2, current - delta);
    const right = Math.min(totalPages - 1, current + delta);

    range.push(1);
    if (left > 2) {
        range.push("...");
    }
    for (let i = left; i <= right; i++) {
        range.push(i);
    }
    if (right < totalPages - 1) {
        range.push("...");
    }
    range.push(totalPages);
    return range;
}
