import { Modal } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import LoadingAnimation from "../LoadingAnimation";

const PreviewModal = ({
  popupOpen,
  handlePopupClose,
  pdfLoading,
  previewType,
  isTenancyTypeHMO,
  tenants,
  selectedTenant,
  showOriginalReport,
  setShowOriginalReport,
  setSelectedTenant,
  report,
  handlePdfPreview,
}) => {
  return (
    <Modal
      open={popupOpen}
      onClose={handlePopupClose}
      className="flex justify-center items-center"
    >
      <div
        className={`flex flex-col ${
          pdfLoading ? "items-center" : "items-start"
        } bg-white w-[767px] h-fit p-8 pt-6 gap-8 rounded-md`}
      >
        <div className="w-full justify-between flex text-[#010101 text-[22px] font-semibold">
          {previewType === "preview" ? "Preview Report" : "Download Report"}
          <button onClick={handlePopupClose} className="text-[#090909]">
            <CloseRounded className={"text-coolBlue"} />
          </button>
        </div>
        {pdfLoading ? (
          <LoadingAnimation />
        ) : isTenancyTypeHMO ? (
          <>
            <div className="flex gap-6 w-full flex-col">
              <span className="font-semibold text-lg">Original Report</span>
              <div className="grid gap-y-8 gap-x-32 grid-cols-3">
                {tenants?.map((item, index) => (
                  <div className="flex gap-6 items-center" key={index}>
                    <input
                      type="radio"
                      className={`w-4 h-4 bg-[#FEFEFF] text-coolBlue`}
                      checked={
                        selectedTenant?.email === item.email &&
                        showOriginalReport
                      }
                      onClick={() => {
                        setShowOriginalReport(true);
                        setSelectedTenant(item);
                      }}
                    />
                    <span className="font-normal text-sm">{item.name}</span>
                  </div>
                ))}
              </div>
              <span
                className={`font-semibold text-lg ${
                  report?.type === "sent" || report?.status === "approved"
                    ? "flex"
                    : "hidden"
                }`}
              >
                Signed Report
              </span>
              <div
                className={`grid gap-y-8 gap-x-32 grid-cols-3 font-semibold text-lg ${
                  report?.type === "sent" || report?.status === "approved"
                    ? "flex"
                    : "hidden"
                }`}
              >
                {tenants?.map((item, index) => (
                  <div className="flex gap-6 items-center" key={index}>
                    <input
                      type="radio"
                      className={`w-4 h-4 bg-[#FEFEFF] text-coolBlue`}
                      checked={
                        selectedTenant?.email === item.email &&
                        !showOriginalReport
                      }
                      onClick={() => {
                        setShowOriginalReport(false);
                        setSelectedTenant(item);
                      }}
                    />
                    <span className="font-normal text-sm">{item.name}</span>
                  </div>
                ))}
              </div>
            </div>
          </>
        ) : (
          <div className="flex gap-6 w-full">
            <div className="flex gap-2 items-center">
              <input
                type="radio"
                id="original-report"
                className={`w-5 h-5 bg-[#FEFEFF] text-coolBlue`}
                checked={showOriginalReport}
                onClick={() => {
                  setShowOriginalReport(true);
                }}
              />
              <label htmlFor="original-report" className="font-normal text-sm">
                Original Report
              </label>
            </div>
            <div
              className={`flex gap-2 items-center ${
                report?.type === "sent" || report?.status === "approved"
                  ? "flex"
                  : "hidden"
              }`}
            >
              <input
                type="radio"
                id="sent-report"
                className={`w-5 h-5 bg-[#FEFEFF] text-coolBlue`}
                checked={!showOriginalReport}
                onClick={() => {
                  setShowOriginalReport(false);
                }}
              />
              <label htmlFor="sent-report" className="font-normal text-sm">
                Signed Report
              </label>
            </div>
          </div>
        )}
        <div className="flex justify-center w-full items-center">
          <button className="primary-button" onClick={handlePdfPreview}>
            {previewType === "preview" ? "Preview Report" : "Download Report"}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default PreviewModal;
