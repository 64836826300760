import {
  CheckOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@mui/icons-material";
import React, { useCallback, useEffect, useState } from "react";
import Input from "../../components/Input/Input";
import { useLocation, useNavigate } from "react-router-dom";
import { useToastState } from "../../contexts/toastContext";
import axios from "axios";
import { API_URL } from "../../utils/constants";
import { useAuthState } from "../../contexts/authContext";
import AlertDialog from "../../components/AlertDialog";
import { validateEmail } from "../../utils/helper";
import { useAllDataState } from "../../contexts/allDataContext";
import BreadcrumbBackButton from "../../components/BreadcrumbBackButton/BreadcrumbBackButton";

const EditBranchView = () => {
  const navigate = useNavigate();
  const {
    state: { item, isEdit },
  } = useLocation();

  const { triggerToast } = useToastState();
  const { token, user } = useAuthState();
  const { getBranches, getCustomers } = useAllDataState();

  const [form, setForm] = useState({
    email: "",
    name: "",
    contact_number: "",
  });

  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (item) {
      setForm({
        email: item?.email,
        name: item?.name,
        contact_number: item?.contact_number,
      });
    }
    if (isEdit) {
      setIsEditing(true);
    } else {
      setIsEditing(false);
    }
  }, [item, isEdit]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const updateBranch = useCallback(async () => {
    if (form.email === "" || form.name === "") {
      triggerToast("Incomplete fields!", "warning");
      return;
    }

    if (!validateEmail(form.email)) {
      triggerToast("Email is not valid!", "error");
      return;
    }

    try {
      const response = await axios.put(
        `${API_URL}/account/branch/${item._id}`,
        {
          name: form.name,
          email: form?.email,
          contact_number: form?.contact_number,
        },
        {
          headers: { Authorization: "Bearer " + token },
        }
      );
      const data = await response.data;
      setForm({
        email: "",
        name: "",
        contact_number: "",
      });
      getBranches();
      getCustomers();
      navigate("/branches");
      triggerToast("Branch updated successfully!", "success");
    } catch (error) {
      triggerToast("Could not update branch!", "error");
    }
  }, [token, form, triggerToast, item, navigate, getBranches]);

  const deleteBranch = useCallback(async () => {
    try {
      const response = await axios.delete(
        `${API_URL}/account/branch/${item._id}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      const data = await response.data;
      triggerToast("Branch deleted successfully!", "success");
      getBranches();
      navigate("/branches");
    } catch (error) {
      triggerToast(error.response.data.message, "error");
    }
  }, [item, token, triggerToast, navigate, getBranches]);

  const [deleteBranchDialogOpen, setDeleteBranchDialogOpen] = useState(false);
  const handleDeleteBranchDialogOpen = () => setDeleteBranchDialogOpen(true);
  const handleDeleteBranchDialogClose = () => setDeleteBranchDialogOpen(false);

  return (
    <div>
      <div className="flex md:flex-row flex-col items-start justify-between mt-6">
        <div className="flex gap-4 items-center">
          <BreadcrumbBackButton onClick={() => navigate(-1)} />
          <span className="text-xl md:text-2xl font-semibold md:font-bold text-[#2D3436]">
            {isEditing ? "Edit" : "View"} Branch{" "}
          </span>
        </div>
        <div
          className={
            "flex gap-2 justify-between md:justify-end md:mt-0 mt-4 w-full md:w-fit"
          }
        >
          {user?.role !== "customer" ? (
            <button
              className="secondary-button w-full md:w-auto"
              onClick={handleDeleteBranchDialogOpen}
            >
              <DeleteOutlined fontSize={"small"} className={"mr-2"} /> Delete
            </button>
          ) : (
            <div></div>
          )}
          {!isEditing && (
            <button
              className="secondary-button w-full md:w-auto"
              onClick={() => setIsEditing(true)}
            >
              <EditOutlined fontSize={"small"} className={"mr-2"} />
              Edit
            </button>
          )}
        </div>
      </div>

      <div className="flex flex-col gap-4 mt-8">
        <div className="flex flex-col md:flex-row justify-between gap-4">
          <Input
            header={"Name"}
            type={"text"}
            placeholder={"Name"}
            name="name"
            value={form.name}
            onChange={handleChange}
            required={true}
            disabled={!isEditing}
          />
          <Input
            header={"Email"}
            type={"text"}
            placeholder={"Email"}
            name="email"
            value={form.email}
            onChange={handleChange}
            required={true}
            // disabled={!isEditing}
            disabled={true}
          />
        </div>
        <div className="flex flex-col md:flex-row justify-between gap-4">
          <Input
            header={"Contact Number"}
            type={"text"}
            placeholder={"Contact Number"}
            name="contact_number"
            value={form.contact_number}
            onChange={handleChange}
            required={false}
          />
        </div>
      </div>

      <div className="flex justify-center md:justify-end items-center my-10">
        <button
          className="primary-button
                disabled:bg-gray-200 disabled:cursor-not-allowed flex gap-[1px] items-center"
          onClick={updateBranch}
          disabled={!isEditing}
        >
          <CheckOutlined /> Save
        </button>
      </div>

      <AlertDialog
        open={deleteBranchDialogOpen}
        handleClose={handleDeleteBranchDialogClose}
        accept={deleteBranch}
        content={"Delete this branch?"}
      />
    </div>
  );
};

export default EditBranchView;
