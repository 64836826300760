import { CheckOutlined } from "@mui/icons-material";
import React, { useCallback, useState } from "react";
import Input from "../../components/Input/Input";
import { useNavigate } from "react-router-dom";
import { useToastState } from "../../contexts/toastContext";
import axios from "axios";
import { API_URL } from "../../utils/constants";
import { useAuthState } from "../../contexts/authContext";
import { validateEmail } from "../../utils/helper";
import { useAllDataState } from "../../contexts/allDataContext";
import BreadcrumbBackButton from "../../components/BreadcrumbBackButton/BreadcrumbBackButton";

const AddUserView = () => {
  const navigate = useNavigate();

  const { triggerToast } = useToastState();
  const { token } = useAuthState();
  const { getBranches } = useAllDataState();

  const [form, setForm] = useState({
    email: "",
    name: "",
    contact_number: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const addBranch = useCallback(async () => {
    if (form.email === "" || form.name === "") {
      triggerToast("Incomplete fields!", "warning");
      return;
    }

    if (!validateEmail(form.email)) {
      triggerToast("Email is not valid!", "error");
      return;
    }

    try {
      const response = await axios.post(
        `${API_URL}/account/branch`,
        {
          name: form.name,
          email: form.email,
          contact_number: form.contact_number || "",
        },
        {
          headers: { Authorization: "Bearer " + token },
        }
      );
      const data = await response.data;
      setForm({
        email: "",
        name: "",
        contact_number: "",
      });
      getBranches();
      navigate("/branches");
      triggerToast("Branch added successfully!", "success");
    } catch (error) {
      triggerToast("Could not add branch!", "error");
    }
  }, [token, form, triggerToast, navigate, getBranches]);

  return (
    <div>
      <div className="flex gap-4 items-center mt-6">
        <BreadcrumbBackButton onClick={() => navigate(-1)} />
        <span className="text-xl md:text-2xl font-semibold md:font-bold text-[#2D3436]">
          Add Branch
        </span>
      </div>

      <div className="flex flex-col gap-4 mt-8">
        <div className="flex flex-col md:flex-row justify-between gap-4">
          <Input
            header={"Name"}
            type={"text"}
            placeholder={"Name"}
            name="name"
            value={form.name}
            onChange={handleChange}
            required={true}
          />

          <Input
            header={"Email"}
            type={"text"}
            placeholder={"Email"}
            name="email"
            value={form.email}
            onChange={handleChange}
            required={true}
          />
        </div>
        <div className="flex flex-col md:flex-row justify-between gap-4">
          <Input
            header={"Contact Number"}
            type={"text"}
            placeholder={"Contact Number"}
            name="contact_number"
            value={form.contact_number}
            onChange={handleChange}
            required={false}
          />
        </div>
      </div>
      <div className="flex justify-center md:justify-end my-10">
        <button
          className="primary-button flex gap-[1px] items-center"
          onClick={addBranch}
        >
          <CheckOutlined /> Save
        </button>
      </div>
    </div>
  );
};

export default AddUserView;
