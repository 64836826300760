import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SvgIcon from "@mui/material/SvgIcon";

const DropdownMenuPortal = ({ options = [] }) => {
    const [open, setOpen] = useState(false);
    const [position, setPosition] = useState({ top: 0, left: 0 });

    const buttonRef = useRef(null);
    const menuRef = useRef(null);

    const handleToggle = () => {
        // If already open, just close
        if (open) {
            setOpen(false);
            return;
        }

        // Otherwise, position and open
        if (buttonRef.current) {
            const rect = buttonRef.current.getBoundingClientRect();
            setPosition({
                top: rect.bottom + window.scrollY + 4,
                left: rect.left + window.scrollX,
            });
        }
        setOpen(true);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            // Skip if the menu is not open
            if (!open) return;

            // If click is outside both the button and the menu, close dropdown
            if (
                buttonRef.current &&
                !buttonRef.current.contains(event.target) &&
                menuRef.current &&
                !menuRef.current.contains(event.target)
            ) {
                setOpen(false);
            }
        };

        // Using 'mousedown' or 'click' works, but be aware of differences:
        // - 'click' fires after React onClick, so the menu item’s onClick will register first
        // - 'mousedown' fires before, so you must do the "both refs" check
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [open]);

    return (
        <>
            <button
                ref={buttonRef}
                className="p-2 hover:bg-gray-100 rounded-full"
                onClick={handleToggle}
            >
                <MoreVertIcon fontSize="small" />
            </button>

            {open &&
                ReactDOM.createPortal(
                    <div
                        ref={menuRef}
                        className="absolute min-w-[150px] bg-white border border-gray-200 rounded-md shadow-lg z-[9999] pl-4 pr-4 pt-2 pb-2"
                        style={{
                            top: position.top,
                            left: position.left,
                            position: "fixed",
                        }}
                    >
                        {options.map((option, index) => {
                            return (
                                <button
                                    key={index}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        console.log("Clicked", option.label);
                                        option.onClick?.();
                                        setOpen(false);
                                    }}
                                    style={{ width: "100%" }}
                                    className="flex items-center px-2 py-2 w-full text-sm font-medium text-gray-800 hover:text-coolBlue hover:bg-gray-50 border-b border-gray-200 last:border-b-0"
                                >
                                    {option.icon && (
                                        <SvgIcon
                                            component={option.icon}
                                            fontSize="small"
                                            className="mr-2 text-coolBlue "
                                        />
                                    )}
                                    <span>{option.label}</span>
                                </button>
                            );
                        })}
                    </div>,
                    document.body
                )}
        </>
    );
};

export default DropdownMenuPortal;
