import { photo_bw, upload_photo } from "../../assets";
import { DeleteForeverRounded } from "@mui/icons-material";
import AlertDialog from "../AlertDialog";
import React, { useState } from "react";
import { useAuthState } from "../../contexts/authContext";

const UploadPhoto = ({
  form,
  getRootProps,
  getInputProps,
  handleDeletePhotoDialogOpen,
  deletePhotoDialogOpen,
  handleDeletePhotoDialogClose,
  deletePhoto,
  itemName,
  title,
  userRole,
  width,
  isAllowMultiple,
}) => {
  const { user } = useAuthState();
  const [photoToDelete, setPhotoToDelete] = useState(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const handleDeleteClick = (photo) => {
    setPhotoToDelete(photo);
    setShowDeleteDialog(true);
  };

  const handleConfirmDelete = () => {
    deletePhoto(photoToDelete);
    setShowDeleteDialog(false);
    setPhotoToDelete(null);
  };

  const handleCancelDelete = () => {
    setShowDeleteDialog(false);
    setPhotoToDelete(null);
  };

  // Filter out old photos from the photos array
  const newPhotos = Array.isArray(form[itemName])
    ? form[itemName].filter((photo) => !form?.old_photos?.includes(photo))
    : [];


  return (
    <div className={`flex flex-col gap-2 w-full md:${width || "w-1/2"}`}>
      <div className="flex items-center gap-4">
        <span className="text-sm font-medium text-[#151515]">
          {title ? title : "Photo"}
        </span>
        {newPhotos?.length > 0 && (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
          </div>
        )}
      </div>
      {(!newPhotos || newPhotos?.length === 0) && !form?.old_photos?.length && (
        <div
          className="w-full h-[200px] border-2 border-dashed bg-white flex flex-col justify-center items-center gap-4 cursor-pointer"
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <img src={upload_photo} alt="docs" />
          <span className="text-sm text-[#686868] font-medium">
            Click or drag a file to this area to upload.
          </span>
        </div>
      )}
      <div className="flex flex-col gap-4">
        {isAllowMultiple &&
          (newPhotos?.length > 0 || form?.old_photos?.length > 0) && (
            <div
              className="w-full h-[200px] border-2 border-dashed bg-white flex flex-col justify-center items-center gap-4 cursor-pointer"
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              <img src={upload_photo} alt="docs" />
              <span className="text-sm text-[#686868] font-medium">
                Click or drag a file to this area to upload.
              </span>
            </div>
          )}

        {/* Old Photos */}
        {form?.old_photos?.length > 0 && Array.isArray(form.old_photos) && (
          <div className="flex flex-wrap gap-4 mt-4">
            {form.old_photos.map((item) => (
              <div className="flex bg-white items-center justify-start">
                <div className="flex flex-col items-center rounded-lg bg-gray-100 p-2">
                  <div className="px-4 py-2">
                    <img
                      src={item.length > 0 ? item : photo_bw}
                      alt="photo_bw"
                      className={`h-[120px] w-[120px] object-cover rounded-lg`}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        {/* New Photos */}
        {newPhotos?.length > 0 && Array.isArray(newPhotos) ? (
          <div className="flex flex-wrap gap-4 mt-4">
            {newPhotos.map((item, index) => (
              <div
                key={index}
                className="flex bg-white items-center justify-start"
              >
                <div className="flex flex-col items-center rounded-lg bg-[#EFEAFE] p-2">
                  <div className="flex justify-end w-full items-end">
                    {!(userRole && userRole.includes(user?.role)) && (
                      <button onClick={() => handleDeleteClick(item)}>
                        <DeleteForeverRounded className={"text-coolBlue"} />
                      </button>
                    )}
                  </div>
                  <div className="px-4 py-2">
                    <img
                      src={item.length > 0 ? item : photo_bw}
                      alt="photo_bw"
                      className={`h-[120px] w-[120px] object-cover rounded-lg`}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : newPhotos && newPhotos.length > 0 ? (
          <div className="flex bg-white items-center justify-start mt-4">
            <div className="flex flex-col items-center rounded-lg bg-[#EFEAFE] p-2">
              {!(userRole && userRole.includes(user?.role)) && (
                <button onClick={() => handleDeleteClick(newPhotos)}>
                  <DeleteForeverRounded className={"text-coolBlue"} />
                </button>
              )}
              <div className="px-4 py-2">
                <img
                  src={newPhotos ? newPhotos : photo_bw}
                  alt="photo_bw"
                  className="h-[120px] w-[120px] object-cover rounded-lg"
                />
              </div>
            </div>
          </div>
        ) : null}
      </div>

      <AlertDialog
        open={showDeleteDialog}
        handleClose={handleCancelDelete}
        accept={handleConfirmDelete}
        content={"Delete this item?"}
      />
    </div>
  );
};

export default UploadPhoto;
