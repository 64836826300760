import { COLOR_SCHEMES } from "../../utils/constants";
import { getOverviewTypeTitle } from "../../utils/helper";
import { ChevronRightRounded } from "@mui/icons-material";
import React from "react";

const ReportItemsCard = ({ data, status, handleModalOpen, reportType }) => {
  const key =
    data.title
      .toLowerCase()
      .replaceAll(" ", "_")
      .replace(
        "&",
        data.title.toLowerCase().replaceAll(" ", "") ===
          "rooms&areas"
          ? "and"
          : "_"
      );

  const colorKey = (status && key && status[key]) ? status[key] : false;
  const colorScheme = COLOR_SCHEMES[colorKey + "_status"];
  const textColor = colorScheme?.text;
  const backgroundColor = colorScheme?.bg;
  const borderColor = colorScheme?.text;

  return (
    <div
      key={data.title}
      className="bg-[#F4F4F6] w-full flex justify-between items-center p-4 rounded-md"
    >
      <span
        className="font-semibold text-sm text-[#2D3436] cursor-pointer w-full"
        onClick={() => handleModalOpen(data.title)}
      >
        {data.title.toLowerCase().replace(/\s/g, "") === "checkinoverview"
          ? getOverviewTypeTitle(reportType)
          : data.title}
      </span>
      <div className="flex gap-4 items-center">

        {status ? (
          <span
            className="text-sm px-3 py-2 rounded-full w-max capitalize"
            style={{
              color: textColor,
              backgroundColor: backgroundColor,
              borderColor: borderColor,
            }}
          >
            {status &&
              status[
              data.title
                .toLowerCase()
                .replaceAll(" ", "_")
                .replace(
                  "&",
                  data.title.toLowerCase().replaceAll(" ", "") ===
                    "rooms&areas"
                    ? "and"
                    : "_"
                )
              ]}
          </span>
        ) : (
          <div className="flex min-h-[40px] flex-1 justify-center items-center">
            <div className="w-[22px] h-[22px] border-2 border-coolBlue border-t-gray-200 animate-spin rounded-full"></div>
          </div>
        )}
        <button
          onClick={() => handleModalOpen(data.title)}
          className="hidden md:block"
        >
          <ChevronRightRounded fontSize="medium" />
        </button>
      </div>
    </div>
  );
};

export default ReportItemsCard;
