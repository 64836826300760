import React, { useEffect, useState } from "react";
import { logo } from "../../assets";
import { Drawer } from "@mui/material";
import { ChevronRight, LogoutRounded, Menu } from "@mui/icons-material";
import axios from "axios";
import { useAuthState } from "../../contexts/authContext";
import { ROUTES } from "./routes";
import { Link } from "react-router-dom";
import "./Sidebar.css";
import CustomSelect from "../../components/CustomSelect";
import { useAllDataState } from "../../contexts/allDataContext";
import { API_URL } from "../../utils/constants";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {
  setUserBranchInLocalStorage,
  getUserBranchFromLocalStorage,
} from "../../utils/helper";

const SidebarComponent = () => {
  const { user, logoutUser, branch } = useAuthState();
  const { token } = useAuthState();
  const [open, setOpen] = useState(false);
  const toggleDrawer = () => setOpen(!open);
  const { branches } = useAllDataState();
  const [selectedBranch, setSelectedBranch] = useState(
    getUserBranchFromLocalStorage()
  );
  const [selectedBranchRef, setSelectedBranchRef] = useState(
    getUserBranchFromLocalStorage() | branch
  );
  const [activeRoute, setActiveRoute] = useState("");

  const [branchChangeConfirmationDialogOpen, setBranchChangeConfirmationDialogOpen] = useState(false);
  const handleBranchChangeDialogOpen = () => setBranchChangeConfirmationDialogOpen(true);
  const handleBranchChangeDialogClose = () => setBranchChangeConfirmationDialogOpen(false);

  const handleBranchChange = (e) => {
    // if (e?.target?.value) {
    setSelectedBranchRef(e?.target?.value);
    handleBranchChangeDialogOpen();
    // }
  };

  const updateUserBranch = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/account/user/selectBranch/${selectedBranchRef}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      const data = await response.data;
      setSelectedBranch(data?.user?.selected_branch_id);
      setSelectedBranchRef(data?.user?.selected_branch_id);
      setUserBranchInLocalStorage(data?.user?.selected_branch_id);
      handleBranchChangeDialogClose();
      window.location.reload();
    } catch (error) {
      console.log("error: ", error);
    }
  };

  useEffect(() => {
    setActiveRoute(window.location.pathname.toLowerCase().split("/")[1]);
  }, [window.location.pathname]);

  return (
    <>
      <div className="w-screen flex justify-between items-center px-3 py-4 md:hidden">
        <img src={logo} className="w-[180px]" alt="" />
        <Menu onClick={toggleDrawer} />
      </div>

      <Drawer anchor="right" open={open} onClose={toggleDrawer}>
        <div className="w-[245px] h-screen flex flex-col justify-between py-8 bg-[#FBFCFF] border-r border-[#0000000D]">
          <div className="flex flex-col gap-12">
            <div className={"flex justify-center"}>
              <img src={logo} className="w-[123px] mx-8" alt="" />
            </div>
            {/* @TODO COMMENT OUT TO BE ABLE CHANGE BETWEEN BRANCHES #160 */}
            {/* {(user?.role === "superadmin" || user?.role === "admin") && (
              <div className="flex flex-col md:flex-row justify-between gap-4 p-5">
                <CustomSelect
                  header={"Branch"}
                  name="branch"
                  value={selectedBranch}
                  handleChange={handleBranchChange}
                  data={branches.map((branch) => {
                    return {
                      label: branch.name,
                      value: branch.viewBranch?.item?._id,
                    };
                  })}
                  placeholder={"Branches"}
                  DataType="2"
                />
              </div>
            )} */}
            <div className="flex flex-col">
              {user &&
                user.role &&
                ROUTES.map((route, index) => {
                  if (user.role === "customer") {
                    if (
                      route.name === "Reports" ||
                      route.name === "Properties"
                    ) {
                      return (
                        <React.Fragment key={route.id}>
                          <Link
                            to={`/${route.name.toLowerCase()}`}
                            className={`flex gap-3 items-center cursor-pointer hover:bg-[#EFEAFE] rounded-lg m-1 py-2 px-8 ${activeRoute.includes(route.name.toLowerCase()) &&
                              "bg-[#EFEAFE]"
                              }`}
                          >
                            {activeRoute.includes(route.name.toLowerCase())
                              ? route.active_icon
                              : route.icon}
                            <span
                              className={`text-sm font-bold ${activeRoute.includes(
                                route.name.toLowerCase()
                              ) && "text-coolBlue"
                                }`}
                            >
                              {route.name}
                            </span>
                            <div className="ml-auto">
                              <ChevronRight
                                style={{
                                  color: activeRoute.includes(
                                    route.name.toLowerCase()
                                  )
                                    ? "#5131D7"
                                    : "",
                                }}
                              />
                            </div>
                          </Link>
                        </React.Fragment>
                      );
                    }
                    return null;
                  } else if (
                    user.role === "admin" ||
                    user.role === "superadmin"
                  ) {
                    if (
                      route.name === "Settings" ||
                      route.name === "Users" ||
                      route.name === "Templates"
                    ) {
                      return (
                        <React.Fragment key={route.id}>
                          {route.id === 6 && (
                            <div
                              className={"ml-8 font-bold  text-sm mt-8 mb-4"}
                            >
                              Admin
                            </div>
                          )}
                          <Link
                            to={`/${route.name.toLowerCase()}`}
                            className={`flex gap-3 items-center cursor-pointer hover:bg-[#EFEAFE] rounded-lg m-1 py-2 px-8 ${activeRoute.includes(route.name.toLowerCase()) &&
                              "bg-[#EFEAFE]"
                              }`}
                          >
                            {activeRoute.includes(route.name.toLowerCase())
                              ? route.active_icon
                              : route.icon}
                            <span
                              className={` text-sm font-bold ${activeRoute.includes(
                                route.name.toLowerCase()
                              ) && "text-coolBlue"
                                }`}
                            >
                              {route.name}
                            </span>
                            <div className="ml-auto">
                              <ChevronRight
                                style={{
                                  color: activeRoute.includes(
                                    route.name.toLowerCase()
                                  )
                                    ? "#5131D7"
                                    : "",
                                }}
                              />
                            </div>
                          </Link>
                        </React.Fragment>
                      );
                    } else {
                      return (
                        <React.Fragment key={route.id}>
                          <Link
                            to={`/${route.name.toLowerCase()}`}
                            className={`flex gap-3 items-center cursor-pointer hover:bg-[#EFEAFE] rounded-lg m-1 py-2 px-8 ${activeRoute.includes(route.name.toLowerCase()) &&
                              "bg-[#EFEAFE]"
                              }`}
                          >
                            {activeRoute.includes(route.name.toLowerCase())
                              ? route.active_icon
                              : route.icon}
                            <span
                              className={` text-sm font-bold ${activeRoute.includes(
                                route.name.toLowerCase()
                              ) && "text-coolBlue"
                                }`}
                            >
                              {route.name}
                            </span>
                            <div className="ml-auto">
                              <ChevronRight
                                style={{
                                  color: activeRoute.includes(
                                    route.name.toLowerCase()
                                  )
                                    ? "#5131D7"
                                    : "",
                                }}
                              />
                            </div>
                          </Link>
                        </React.Fragment>
                      );
                    }
                  } else if (user.role === "manager") {
                    if (
                      route.name === "Settings" ||
                      route.name === "Templates" ||
                      route.name === "Branches"
                    ) {
                      return null;
                    } else {
                      return (
                        <React.Fragment key={route.id}>
                          <Link
                            to={`/${route.name.toLowerCase()}`}
                            className={`flex gap-3 items-center cursor-pointer hover:bg-[#EFEAFE] rounded-lg m-1 py-2 px-8 ${activeRoute.includes(route.name.toLowerCase()) &&
                              "bg-[#EFEAFE]"
                              }`}
                          >
                            {activeRoute.includes(route.name.toLowerCase())
                              ? route.active_icon
                              : route.icon}
                            <span
                              className={` text-sm font-bold ${activeRoute.includes(
                                route.name.toLowerCase()
                              ) && "text-coolBlue"
                                }`}
                            >
                              {route.name}
                            </span>
                            <div className="ml-auto">
                              <ChevronRight
                                style={{
                                  color: activeRoute.includes(
                                    route.name.toLowerCase()
                                  )
                                    ? "#5131D7"
                                    : "",
                                }}
                              />
                            </div>
                          </Link>
                        </React.Fragment>
                      );
                    }
                  } else if (user.role === "clerk") {
                    if (
                      route.name === "Settings" ||
                      route.name === "Users" ||
                      route.name === "Templates" ||
                      route.name === "Branches"
                    ) {
                      return null;
                    } else {
                      return (
                        <React.Fragment key={route.id}>
                          <Link
                            to={`/${route.name.toLowerCase()}`}
                            className={`flex gap-3 items-center cursor-pointer hover:bg-[#EFEAFE] rounded-lg m-1 py-2 px-8 ${activeRoute.includes(route.name.toLowerCase()) &&
                              "bg-[#EFEAFE]"
                              }`}
                          >
                            {activeRoute.includes(route.name.toLowerCase())
                              ? route.active_icon
                              : route.icon}
                            <span
                              className={` text-sm font-bold ${activeRoute.includes(
                                route.name.toLowerCase()
                              ) && "text-coolBlue"
                                }`}
                            >
                              {route.name}
                            </span>
                            <div className="ml-auto">
                              <ChevronRight
                                style={{
                                  color: activeRoute.includes(
                                    route.name.toLowerCase()
                                  )
                                    ? "#5131D7"
                                    : "",
                                }}
                              />
                            </div>
                          </Link>
                        </React.Fragment>
                      );
                    }
                  } else {
                    if (route.name === "Users") {
                      return null;
                    }
                    return (
                      <React.Fragment key={route.id}>
                        <Link
                          to={`/${route.name.toLowerCase()}`}
                          className={`flex gap-3 items-center cursor-pointer hover:bg-[#EFEAFE] rounded-lg m-1 py-2 px-8 ${activeRoute.includes(route.name.toLowerCase()) &&
                            "bg-[#EFEAFE]"
                            }`}
                        >
                          {activeRoute.includes(route.name.toLowerCase())
                            ? route.active_icon
                            : route.icon}
                          <span
                            className={`text-sm font-bold ${activeRoute.includes(route.name.toLowerCase()) &&
                              "text-coolBlue"
                              }`}
                          >
                            {route.name}
                          </span>
                        </Link>
                      </React.Fragment>
                    );
                  }
                })}
              <div className={"mt-20 flex justify-center"}>
                <button
                  onClick={logoutUser}
                  className="secondary-button w-[80%]"
                >
                  Logout
                  <LogoutRounded fontSize="small" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </Drawer>

      <div className="h-screen overflow-scroll no-scrollbar hidden md:w-[245px] md:flex flex-col justify-between py-8 bg-[#FBFCFF] border-r border-[#0000000D] sidebar_placement">
        <div className="flex flex-col gap-6">
          <div className={"flex justify-center"}>
            <img src={logo} className="w-[180px] mx-8" alt="" />
          </div>
          {/* @TODO COMMENT OUT TO BE ABLE CHANGE BETWEEN BRANCHES #160 */}
          <span className=" text-sm font-bold text-center">Logged in as {user?.name}</span>
          {(user?.role === "superadmin" || user?.role === "admin") && (
            <div className="flex flex-col md:flex-row justify-between gap-4 p-5">
              <CustomSelect
                header={"Branch"}
                name="branch"
                value={selectedBranch}
                handleChange={handleBranchChange}
                data={[
                  ...(user?.role === "superadmin"
                    ? [{ label: "All Branches", value: null }]
                    : []),
                  ...branches.map((branch) => ({
                    label: branch.name,
                    value: branch.viewBranch?.item?._id,
                  })),
                ]}
                placeholder={"Branches"}
                DataType="2"
              />
            </div>
          )}

          <div className="flex flex-col">
            {user &&
              user.role &&
              ROUTES.map((route, index) => {
                if (user.role === "customer") {
                  if (route.name === "Reports" || route.name === "Properties") {
                    return (
                      <React.Fragment key={route.id}>
                        <Link
                          to={`/${route.name.toLowerCase()}`}
                          className={`flex gap-3 items-center cursor-pointer hover:bg-[#EFEAFE] rounded-lg m-1 py-2 px-8 ${activeRoute.includes(route.name.toLowerCase()) &&
                            "bg-[#EFEAFE]"
                            }`}
                        >
                          {activeRoute.includes(route.name.toLowerCase())
                            ? route.active_icon
                            : route.icon}
                          <span
                            className={`text-sm font-bold ${activeRoute.includes(route.name.toLowerCase()) &&
                              "text-coolBlue"
                              }`}
                          >
                            {route.name}
                          </span>
                          <div className="ml-auto">
                            <ChevronRight
                              style={{
                                color: activeRoute.includes(
                                  route.name.toLowerCase()
                                )
                                  ? "#5131D7"
                                  : "",
                              }}
                            />
                          </div>
                        </Link>
                      </React.Fragment>
                    );
                  }
                  return null;
                } else if (
                  user.role === "admin" ||
                  user.role === "superadmin"
                ) {
                  if (
                    route.name === "Settings" ||
                    route.name === "Users" ||
                    route.name === "Templates" ||
                    route.name === "Branches"
                  ) {
                    return (
                      <React.Fragment key={route.id}>
                        {route.id === 6 && (
                          <div className={"ml-8 font-bold  text-sm mt-8 mb-4"}>
                            Admin
                          </div>
                        )}
                        <Link
                          to={`/${route.name.toLowerCase()}`}
                          className={`flex gap-3 items-center cursor-pointer hover:bg-[#EFEAFE] rounded-lg m-1 py-2 px-8 ${activeRoute.includes(route.name.toLowerCase()) &&
                            "bg-[#EFEAFE]"
                            }`}
                        >
                          {activeRoute.includes(route.name.toLowerCase())
                            ? route.active_icon
                            : route.icon}
                          <span
                            className={` text-sm font-bold ${activeRoute.includes(route.name.toLowerCase()) &&
                              "text-coolBlue"
                              }`}
                          >
                            {route.name}
                          </span>
                          <div className="ml-auto">
                            <ChevronRight
                              style={{
                                color: activeRoute.includes(
                                  route.name.toLowerCase()
                                )
                                  ? "#5131D7"
                                  : "",
                              }}
                            />
                          </div>
                        </Link>
                      </React.Fragment>
                    );
                  } else {
                    return (
                      <React.Fragment key={route.id}>
                        <Link
                          to={`/${route.name.toLowerCase()}`}
                          className={`flex gap-3 items-center cursor-pointer hover:bg-[#EFEAFE] rounded-lg m-1 py-2 px-8 ${activeRoute.includes(route.name.toLowerCase()) &&
                            "bg-[#EFEAFE]"
                            }`}
                        >
                          {activeRoute.includes(route.name.toLowerCase())
                            ? route.active_icon
                            : route.icon}
                          <span
                            className={` text-sm font-bold ${activeRoute.includes(route.name.toLowerCase()) &&
                              "text-coolBlue"
                              }`}
                          >
                            {route.name}
                          </span>
                          <div className="ml-auto">
                            <ChevronRight
                              style={{
                                color: activeRoute.includes(
                                  route.name.toLowerCase()
                                )
                                  ? "#5131D7"
                                  : "",
                              }}
                            />
                          </div>
                        </Link>
                      </React.Fragment>
                    );
                  }
                } else if (user.role === "manager") {
                  if (
                    route.name === "Branches" ||
                    route.name === "Settings" ||
                    route.name === "Templates"
                  ) {
                    return null;
                  } else {
                    return (
                      <React.Fragment key={route.id}>
                        <Link
                          to={`/${route.name.toLowerCase()}`}
                          className={`flex gap-3 items-center cursor-pointer hover:bg-[#EFEAFE] rounded-lg m-1 py-2 px-8 ${activeRoute.includes(route.name.toLowerCase()) &&
                            "bg-[#EFEAFE]"
                            }`}
                        >
                          {activeRoute.includes(route.name.toLowerCase())
                            ? route.active_icon
                            : route.icon}
                          <span
                            className={`text-sm font-bold ${activeRoute.includes(route.name.toLowerCase()) &&
                              "text-coolBlue"
                              }`}
                          >
                            {route.name}
                          </span>
                        </Link>
                      </React.Fragment>
                    );
                  }
                } else if (user.role === "clerk") {
                  if (
                    route.name === "Users" ||
                    route.name === "Branches" ||
                    route.name === "Settings" ||
                    route.name === "Templates"
                  ) {
                    return null;
                  } else {
                    return (
                      <React.Fragment key={route.id}>
                        <Link
                          to={`/${route.name.toLowerCase()}`}
                          className={`flex gap-3 items-center cursor-pointer hover:bg-[#EFEAFE] rounded-lg m-1 py-2 px-8 ${activeRoute.includes(route.name.toLowerCase()) &&
                            "bg-[#EFEAFE]"
                            }`}
                        >
                          {activeRoute.includes(route.name.toLowerCase())
                            ? route.active_icon
                            : route.icon}
                          <span
                            className={`text-sm font-bold ${activeRoute.includes(route.name.toLowerCase()) &&
                              "text-coolBlue"
                              }`}
                          >
                            {route.name}
                          </span>
                        </Link>
                      </React.Fragment>
                    );
                  }
                } else {
                  if (route.name === "Users" || route.name === "Branches") {
                    return null;
                  }
                  return (
                    <React.Fragment key={route.id}>
                      <Link
                        to={`/${route.name.toLowerCase()}`}
                        className={`flex gap-3 items-center cursor-pointer hover:bg-[#EFEAFE] rounded-lg m-1 py-2 px-8 ${activeRoute.includes(route.name.toLowerCase()) &&
                          "bg-[#EFEAFE]"
                          }`}
                      >
                        {activeRoute.includes(route.name.toLowerCase())
                          ? route.active_icon
                          : route.icon}
                        <span
                          className={`text-sm font-bold ${activeRoute.includes(route.name.toLowerCase()) &&
                            "text-coolBlue"
                            }`}
                        >
                          {route.name}
                        </span>
                      </Link>
                    </React.Fragment>
                  );
                }
              })}
            <div className={"mt-20 flex justify-center"}>
              <button onClick={logoutUser} className="secondary-button w-[80%]">
                Logout
                <LogoutRounded fontSize="small" />
              </button>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={branchChangeConfirmationDialogOpen}
        onClose={handleBranchChangeDialogClose}
        aria-labelledby="branch-dialog-title"
        aria-describedby="branch-dialog-description"
      >
        <DialogContent>
          <div className="text-lg mb-1">
            Are you sure you want to switch branches?
          </div>
          <div className="text-sm font-extralight">
            This action will reload the page and any unsaved changes may be lost.
          </div>
        </DialogContent>
        <DialogActions sx={{
          display: "flex",
          justifyContent: "space-between",
          paddingX: "2rem",
          paddingBottom: "2rem",
        }}>
          <button className="primary-button" onClick={handleBranchChangeDialogClose}>Close</button>
          <button className="secondary-button" onClick={updateUserBranch} autoFocus>
            Continue
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SidebarComponent;
