import React, { useEffect } from "react";
import Tbl from "../../components/Table/Tbl";
import { DownloadRounded } from "@mui/icons-material";
import { useAllDataState } from "../../contexts/allDataContext";
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import AccordionTable from "../../components/Accordion";
import { columns } from "./constants";
import { useAuthState } from "../../contexts/authContext";
import TitleButtons from "../../components/MainLayout/TitleButtons";

const UsersView = () => {
  const canEditUser = ["superadmin", "admin"];
  const navigate = useNavigate();
  const { user } = useAuthState();
  const { branches } = useAllDataState();

  useEffect(() => {
    if (!canEditUser.includes(user?.role)) navigate(-1);
  }, [navigate, user]);

  return (
    <div className="pb-1">
      <TitleButtons
        data={branches}
        title={"Branches"}
        addButton={"Branches"}
        disabled={!canEditUser.includes(user?.role)}
      />

      <div className="hidden md:block border border-[#eeeeee] rounded-lg shadow-lg mb-20">
        <Tbl data={branches} columns={columns} type={"Branches"} />
      </div>

      <div className="block md:hidden mt-4 mb-20">
        <AccordionTable data={branches} type={"Branches"} />
      </div>
    </div>
  );
};

export default UsersView;
