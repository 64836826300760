import { CheckOutlined } from "@mui/icons-material";
import React, { useCallback, useEffect, useState } from "react";
import Input from "../../../components/Input/Input";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../../utils/constants";
import { useAuthState } from "../../../contexts/authContext";
import { useToastState } from "../../../contexts/toastContext";
import { useReportState } from "../../../contexts/reportContext";
import { getOverviewTypeTitle } from "../../../utils/helper";
import BreadcrumbBackButton from "../../../components/BreadcrumbBackButton/BreadcrumbBackButton";

const CheckInOverviewView = ({
    handleCheckInOverviewClose,
    checkInOverviewQuestions,
    reportType,
}) => {
    const {
        state: { item },
    } = useLocation();
    const { token } = useAuthState();
    const { triggerToast } = useToastState();
    const { getCheckInOverviewResponse, checkInOverview, getCurrentReportStatus } =
        useReportState();

    const [comments, setComments] = useState("");
    const [propertyInfo, setPropertyInfo] = useState("");
    const [questions, setQuestions] = useState([]);
    const [id, setId] = useState("");

    useEffect(() => {
        /**
         * We look for an existing overview item if it exists.
         * Otherwise, we build out initial questions from `checkInOverviewQuestions`.
         */
        const overviewItem = checkInOverview.find((it) =>
            ["check_in_overview", "check_out_overview", "inspection_overview"].includes(
                it.entity_type
            )
        );

        if (overviewItem) {
            // overviewItem.metadata?.response
            // can have values like This is question 1?_0
            // we need to split and remove "_0"

            const updatedResponse = overviewItem.metadata?.response.map((q) => ({
                ...q,
                question: q?.question?.split("_")[0],
            }));
            // We have existing data
            setComments(overviewItem.metadata?.comment || "");
            setPropertyInfo(overviewItem.metadata?.property_info || "");
            setId(overviewItem._id || "");
            setQuestions(updatedResponse || []);
        } else {
            // No existing data, so initialize `questions` from `checkInOverviewQuestions`
            setComments("");
            setPropertyInfo("");
            setId("");
            setQuestions(
                checkInOverviewQuestions.map((q) => ({
                    question: q.question,
                    // Default user answers to empty (or “N/A” if you prefer):
                    answer: "",
                    // We'll store the if_yes_in_green as well:
                    if_yes_in_green: q.answer || false,
                }))
            );
        }
    }, [checkInOverview, checkInOverviewQuestions]);

    // Simplified addAnswer so it updates the question with the matching `.question`
    const addAnswer = (e) => {
        const { name, value } = e.target; // name is question text, value is "Yes"/"No"/"N/A"

        setQuestions((prev) =>
            prev.map((q) =>
                q.question === name ? { ...q, answer: value } : q
            )
        );
    };

    const addCheckInOverviewResponse = useCallback(
        async (body) => {
            try {
                await axios.post(`${API_URL}/account/report_response`, body, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + token,
                    },
                });
                triggerToast("Check in overview added successfully!", "success");
                getCheckInOverviewResponse(item._id, reportType);
                getCurrentReportStatus(item._id);
            } catch (error) {
                triggerToast(error?.response?.data?.message, "error");
            }
        },
        [
            token,
            triggerToast,
            getCheckInOverviewResponse,
            item,
            reportType,
            getCurrentReportStatus,
        ]
    );

    const updateCheckInOverviewResponse = useCallback(
        async (body) => {
            try {
                await axios.put(`${API_URL}/account/report_response/${id}`, body, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + token,
                    },
                });
                triggerToast("Check in overview updated successfully!", "success");
                getCheckInOverviewResponse(item._id, reportType);
                getCurrentReportStatus(item._id);
            } catch (error) {
                triggerToast(error?.response?.data?.message, "error");
            }
        },
        [
            token,
            triggerToast,
            id,
            getCheckInOverviewResponse,
            item,
            reportType,
            getCurrentReportStatus,
        ]
    );

    /**
     * Just keep the checks the same – but now since `questions`
     * is always the array you’re rendering, the length and answers
     * should be in sync.
     */
    const _addCheckInOverviewResponse = () => {
        const anyUnanswered = questions.some((q) => !q.answer);
        if (anyUnanswered) {
            triggerToast("Please answer all the questions", "error");
            return;
        }
        addCheckInOverviewResponse({
            report_id: item._id,
            entity_type:
                reportType?.toLowerCase().replace(/\s/g, "") === "inspectionreport"
                    ? "inspection_overview"
                    : reportType?.toLowerCase().replace(/\s/g, "") === "checkoutreport" || reportType?.toLowerCase().replace(/\s/g, "") === "checkoutdefects"
                        ? "check_out_overview"
                        : "check_in_overview",
            metadata: {
                comment: comments,
                property_info: propertyInfo,
                response: questions,
            },
        });
        setComments("");
        setPropertyInfo("");
        setQuestions([]);
        handleCheckInOverviewClose();
    };

    const _updateCheckInOverviewResponse = () => {
        updateCheckInOverviewResponse({
            report_id: item._id,
            entity_type:
                reportType?.toLowerCase().replace(/\s/g, "") === "inspectionreport"
                    ? "inspection_overview"
                    : reportType?.toLowerCase().replace(/\s/g, "") === "checkoutreport" || reportType?.toLowerCase().replace(/\s/g, "") === "checkoutdefects"
                        ? "check_out_overview"
                        : "check_in_overview",
            metadata: {
                comment: comments,
                property_info: propertyInfo,
                response: questions,
            },
        });
        setId("");
        handleCheckInOverviewClose();
    };

    return (
        <div className="flex justify-end mt-24 md:mt-0">
            <div className="flex flex-col gap-6 w-full md:w-[calc(100%_-_245px)] bg-[#fff] md:px-4 h-screen overflow-y-scroll pt-4 md:pt-10">
                <div className="flex gap-4 items-center mx-4 md:mx-8">
                    <BreadcrumbBackButton onClick={handleCheckInOverviewClose} />
                    <span className="font-bold text-base md:text-xl text-[#212121]">
                        {getOverviewTypeTitle(reportType)}
                    </span>
                </div>

                <div className="flex flex-col gap-8 md:bg-[#fff] mx-4 md:mx-5 md:p-4 mb-40 md:mb-0">
                    <div className="flex flex-col md:flex-row gap-6">
                        <div className="flex flex-col w-full md:w-fit md:basis-1/2 gap-4 md:gap-2">
                            <span className="font-medium text-sm text-[#282828]">
                                Questions
                            </span>
                            <div className="flex flex-col gap-8 md:bg-white md:p-4">
                                {questions.map((question, idx) => (
                                    <div key={idx} className="flex flex-col gap-4">
                                        <span className="text-sm text-[#282828] font-medium">
                                            {idx + 1}. {question.question}
                                        </span>
                                        <div className="flex flex-col md:flex-row gap-1 md:gap-32 text-sm px-4">
                                            <div className="flex items-center gap-2 ">
                                                <input
                                                    type="radio"
                                                    id={`Yes_${idx}`}
                                                    name={question.question}
                                                    className="cursor-pointer"
                                                    value="Yes"
                                                    onChange={addAnswer}
                                                    checked={question.answer === "Yes"}
                                                />
                                                <label
                                                    htmlFor={`Yes_${idx}`}
                                                    className="text-sm text-[#4D4D4D] cursor-pointer"
                                                >
                                                    Yes
                                                </label>
                                            </div>
                                            <div className="flex items-center gap-2 ">
                                                <input
                                                    type="radio"
                                                    id={`No_${idx}`}
                                                    name={question.question}
                                                    className="cursor-pointer"
                                                    value="No"
                                                    onChange={addAnswer}
                                                    checked={question.answer === "No"}
                                                />
                                                <label
                                                    htmlFor={`No_${idx}`}
                                                    className="text-sm text-[#4D4D4D] cursor-pointer"
                                                >
                                                    No
                                                </label>
                                            </div>
                                            <div className="flex items-center gap-2 ">
                                                <input
                                                    type="radio"
                                                    id={`N/A_${idx}`}
                                                    name={question.question}
                                                    className="cursor-pointer"
                                                    value="N/A"
                                                    onChange={addAnswer}
                                                    checked={question.answer === "N/A"}
                                                />
                                                <label
                                                    htmlFor={`N/A_${idx}`}
                                                    className="text-sm text-[#4D4D4D] cursor-pointer"
                                                >
                                                    N/A
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="flex flex-col w-full md:w-fit md:basis-1/2 gap-6">
                            <div className="flex flex-col gap-4">
                                <Input
                                    placeholder={"Enter comments"}
                                    header={"Comments"}
                                    name="comments"
                                    value={comments}
                                    onChange={(e) => setComments(e.target.value)}
                                    type="textarea"
                                />
                                <Input
                                    placeholder={"Property Information"}
                                    header={"Property Information"}
                                    name="property information"
                                    value={propertyInfo}
                                    onChange={(e) => setPropertyInfo(e.target.value)}
                                    type="textarea"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="w-full flex justify-center md:justify-end mb-10">
                        {id ? (
                            <button className="primary-button" onClick={_updateCheckInOverviewResponse}>
                                <CheckOutlined className="mr-2" fontSize="small" />
                                Save
                            </button>
                        ) : (
                            <button className="primary-button" onClick={_addCheckInOverviewResponse}>
                                <CheckOutlined className="mr-2" fontSize="small" />
                                Save
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CheckInOverviewView;
