import React, { useRef, useEffect, useState, useCallback } from "react";
import ReactDOM from "react-dom";
import $ from "jquery";
import DT from "datatables.net-dt";
import "datatables.net-responsive-dt";
import "./Tbl.css";
import { useNavigate } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  API_URL,
  COLOR_SCHEMES,
  REPORT_ROWS_BG_COLOR,
  cloneReportData,
} from "../../utils/constants";
import {
  ApartmentRounded,
  ChevronRightRounded,
  CloseRounded,
  DeleteForeverRounded,
  Edit,
  EditRounded,
  ModeEditRounded,
  Add,
  Send,
  Visibility,
  Download,
  EditOutlined,
  HolidayVillageOutlined,
  VisibilityOutlined,
  PreviewOutlined,
  DownloadOutlined,
  FileCopyOutlined,
  InsertPhotoOutlined,
  FormatListBulletedOutlined,
  MailOutlined,
  DriveFileRenameOutlineOutlined,
  DeleteOutlined,
  RingVolumeOutlined,
} from "@mui/icons-material";
import { Dialog, DialogActions, Modal, Tooltip } from "@mui/material";
import { useAuthState } from "../../contexts/authContext";
import ReactSignatureCanvas from "react-signature-canvas";
import { logo } from "../../assets";
import axios from "axios";
import { useToastState } from "../../contexts/toastContext";
import LoadingAnimation from "../LoadingAnimation";
import { useAllDataState } from "../../contexts/allDataContext";
import { useReportState } from "../../contexts/reportContext";
import Input from "../Input/Input";
import Checkbox from "../Checkbox/Checkbox";
import { formatDate, validateEmail } from "../../utils/helper";
import AlertDialog from "../AlertDialog";
import {
  PopoverContent,
  PopoverHandler,
  Popover,
} from "@material-tailwind/react";
import { useTemplateState } from "../../contexts/templateContext";
import PreviewModal from "../Modal/PreviewModal";
import ModalComponent from "../Modal";

const Tbl = ({ data, columns, type, ...restProps }) => {
  const { onTenantEdit, onTenantDelete, deleteNote } = restProps;
  const { user } = useAuthState();
  const { getTemplates } = useTemplateState();
  const navigate = useNavigate();
  $.DataTable = DT;
  const tableRef = useRef();
  const ht = window.innerHeight;
  const {
    tenancies,
    getProperties,
    getTenancies,
    getBranches,
    getUsers,
    getCustomers,
    getPropertyList,
  } = useAllDataState();
  const [currentImage, setCurrentImage] = useState("");
  const [open, setOpen] = React.useState(false);
  const [emailOpen, setEmailOpen] = React.useState(false);
  const [signatureOpen, setSignatureOpen] = React.useState(false);
  const [currentReportId, setCurrentReportId] = useState("");
  const [currentReport, setCurrentReport] = useState({});
  const [contacts, setContacts] = useState([]);
  const [tenants, setTenants] = useState([]);
  const [emailLoading, setEmailLoading] = useState(false);
  const { triggerToast } = useToastState();
  const { token } = useAuthState();
  const handleOpen = (url) => {
    setCurrentImage(url);
    setOpen(true);
  };
  const handleClose = () => {
    setCurrentImage("");
    setOpen(false);
  };

  const getTenancyItem = (id) => {
    return tenancies
      .filter((item) => item.viewTenancy.item.report_id !== null)
      .filter((item) => item.viewTenancy.item.report_id._id === id)[0]
      ?.viewTenancy?.item;
  };
  const { allReports, getReports } = useReportState();
  const [popupOpen, setPopupOpen] = useState(false);

  const deleteReport = useCallback(
    async (reportId) => {
      try {
        const response = await axios.delete(
          `${API_URL}/account/report/${reportId}`,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        const data = await response.data;
        getReports();
        getProperties();
        triggerToast("Report deleted successfully!", "success");
      } catch (error) {
        triggerToast(error.response.data.message, "error");
      }
    },
    [getReports, token, triggerToast, navigate, getProperties]
  );

  const deleteTemplate = useCallback(
    async (templateId) => {
      try {
        const response = await axios.delete(
          `${API_URL}/account/template/${templateId}`,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        const data = await response.data;
        getTemplates();
        getProperties();
        triggerToast("Template deleted successfully!", "success");
        navigate("/templates");
      } catch (error) {
        triggerToast(error.response.data.message, "error");
      }
    },
    [getTemplates, token, triggerToast, navigate, getProperties]
  );

  const duplicateTemplate = useCallback(
    async (templateId) => {
      try {
        const response = await axios.post(
          `${API_URL}/account/template/${templateId}/duplicate`,
          {},
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        const data = await response.data;
        getTemplates();
        triggerToast("Template duplicated successfully!", "success");
      } catch (error) {
        triggerToast(error.response.data.message, "error");
      }
    },
    [getTemplates, token, triggerToast]
  );

  const deleteTenant = useCallback(
    async (tenantId) => {
      try {
        const response = await axios.delete(
          `${API_URL}/account/tenancy/${tenantId}`,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        const data = await response.data;
        getTenancies();
        getProperties();
        triggerToast("Tenant deleted successfully!", "success");
        // navigate("/reports");
      } catch (error) {
        triggerToast(error.response.data.message, "error");
      }
    },
    [getTenancies, token, triggerToast, navigate, getProperties]
  );

  const deleteCustomer = useCallback(
    async (itemId) => {
      try {
        const response = await axios.delete(
          `${API_URL}/account/customer/${itemId}`,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        const data = await response.data;
        getCustomers();
        triggerToast("Customer deleted successfully!", "success");
      } catch (error) {
        triggerToast(error.response.data.message, "error");
      }
    },
    [getCustomers, token, triggerToast, navigate]
  );

  const [deleteReportDialogOpen, setDeleteReportDialogOpen] = useState(false);
  const [deleteDialogAccept, setDeleteDialogAccept] = useState();
  const [deleteDialogContent, setDeleteDialogContent] = useState("");

  const handleDeleteReportDialogClose = () => {
    setDeleteReportDialogOpen(false);
  };

  const deleteProperty = useCallback(
    async (propertyId) => {
      try {
        const response = await axios.delete(
          `${API_URL}/account/property/${propertyId}`,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        const data = await response.data;
        getProperties();
        getPropertyList();
        triggerToast("Property deleted successfully!", "success");
        navigate("/properties");
      } catch (error) {
        triggerToast(error.response.data.message, "error");
      }
    },
    [getProperties, token, triggerToast, navigate, getPropertyList]
  );

  const deleteUser = useCallback(
    async (userId) => {
      try {
        const response = await axios.delete(
          `${API_URL}/account/user/${userId}`,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        const data = await response.data;
        getUsers();
        triggerToast("User deleted successfully!", "success");
        navigate("/users");
      } catch (error) {
        triggerToast(error.response.data.message, "error");
      }
    },
    [getUsers, token, triggerToast, navigate]
  );

  const deleteBranch = useCallback(
    async (branchId) => {
      try {
        const response = await axios.delete(
          `${API_URL}/account/branch/${branchId}`,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        const data = await response.data;
        getBranches();
        triggerToast("Branch deleted successfully!", "success");
        navigate("/branches");
      } catch (error) {
        triggerToast(error.response.data.message, "error");
      }
    },
    [getBranches, token, triggerToast, navigate]
  );
  const handleItemDelete = (itemId, type) => {
    if (type === "reports") {
      setDeleteDialogContent("Delete this report?");
      setDeleteReportDialogOpen(true);
      setDeleteDialogAccept(() => () => deleteReport(itemId));
    } else if (type === "templates") {
      setDeleteDialogContent("Delete this template?");
      setDeleteReportDialogOpen(true);
      setDeleteDialogAccept(() => () => deleteTemplate(itemId));
    } else if (type === "tenants") {
      setDeleteDialogContent("Delete this tenancy?");
      setDeleteReportDialogOpen(true);
      setDeleteDialogAccept(() => () => deleteTenant(itemId));
    } else if (type === "customers") {
      setDeleteDialogContent("Delete this customer?");
      setDeleteReportDialogOpen(true);
      setDeleteDialogAccept(() => () => deleteCustomer(itemId));
    } else if (type === "properties") {
      setDeleteDialogContent("Delete this property?");
      setDeleteDialogAccept(() => () => deleteProperty(itemId));
      setDeleteReportDialogOpen(true);
    } else if (type === "users") {
      setDeleteDialogContent("Delete this user?");
      setDeleteReportDialogOpen(true);
      setDeleteDialogAccept(() => () => deleteUser(itemId));
    } else if (type === "branches") {
      setDeleteDialogContent("Delete this branch?");
      setDeleteReportDialogOpen(true);
      setDeleteDialogAccept(() => () => deleteBranch(itemId));
    }
  };

  const [previewPdfDialogOpen, setPreviewPdfDialogOpen] = useState(false);
  const handlePreviewPdfDialogOpen = () => setPreviewPdfDialogOpen(true);
  const handlePreviewPdfDialogClose = () => setPreviewPdfDialogOpen(false);

  const [pdfLoading, setPdfLoading] = useState(false);
  const [previewType, setPreviewType] = useState("");
  const handlePopupClose = () => {
    setSelectedTenant({});
    setPdfLoading(false);
    setIsTenancyTypeHMO(false);
    setPopupOpen(false);
  };

  const [selectedTenant, setSelectedTenant] = useState({});
  const [previewLoading, setPreviewLoading] = useState(false);
  const [isTenancyTypeHMO, setIsTenancyTypeHMO] = useState(false);
  const [previewUrl, setPreviewUrl] = useState("");
  const [report, setReport] = useState({});
  const [showOriginalReport, setShowOriginalReport] = useState(true);

  const handleSetReport = (_id, type) => {
    setReport(allReports.filter((it) => it._id === _id)[0]);
    const tenancy = tenancies.filter(
      (tenancy) => tenancy.viewTenancy.item.report_id?._id === _id
    )[0]?.viewTenancy?.item;
    if (tenancy?.type === "HMO") {
      setIsTenancyTypeHMO(true);
      setTenants(tenancy.tenants);
    }

    type === "download"
      ? (() => {
          setPreviewType("download");
          setPopupOpen(true);
        })()
      : viewPDF(_id, tenancy?.type === "HMO");
  };

  const handlePdfPreview = () => {
    previewType === "download"
      ? previewReport(report?._id)
      : window.open(
          `/view-pdf/${report?._id}?original=${showOriginalReport}${
            selectedTenant._id ? `&id=${selectedTenant._id}` : ""
          }`,
          "_blank"
        );
    handlePopupClose();
  };

  const viewPDF = (reportId, isHMO) => {
    setPopupOpen(true);
    setPreviewType("preview");
  };

  const handleTenantFeedback = (tenantData) => {
    const { onTenantEdit, reportId, tenancyType } = tenantData;
  };

  const [cloneReportPopupOpen, setCloneReportPopupOpen] = useState(false);

  const handleCloneReportPopupClose = () => {
    setCloneReportPopupOpen(false);
    setCloneReportFormData({
      rooms_and_areas: false,
      rooms_and_areas_images: false,
      check_in_overview: false,
      check_in_overview_images: false,
      inspection_overview: false,
      inspection_overview_images: false,
      check_out_overview: false,
      check_out_overview_images: false,
      h_s_compliance: false,
      h_s_compliance_images: false,
      utilities: false,
      utilities_images: false,
      meters: false,
      meters_images: false,
    });
    setCurrentReport({});
  };

  const [cloneReportFormData, setCloneReportFormData] = useState({
    rooms_and_areas: false,
    rooms_and_areas_images: false,
    check_in_overview: false,
    check_in_overview_images: false,
    inspection_overview: false,
    inspection_overview_images: false,
    check_out_overview: false,
    check_out_overview_images: false,
    h_s_compliance: false,
    h_s_compliance_images: false,
    utilities: false,
    utilities_images: false,
    meters: false,
    meters_images: false,
  });

  const [anchorEl, setAnchorEl] = useState(null);

  const handleCloneReport = async () => {
    try {
      await axios.post(
        `${API_URL}/account/report/${currentReport?._id}/clone`,
        cloneReportFormData,
        {
          headers: {
            Authorization: "Token " + token,
          },
        }
      );
      getReports();
      handleCloneReportPopupClose();
      triggerToast(
        `Report ${currentReport?.ref_number} Cloned Successfully`,
        "success"
      );
    } catch (error) {
      console.log(error);
    }
  };

  const previewReport = useCallback(
    async (_id, hideOriginal) => {
      setPreviewLoading(true);
      handlePreviewPdfDialogOpen();
      setPreviewUrl("");
      try {
        const response = await axios.get(
          `${API_URL}/account/report/preview/${_id}?pdf=1&${
            hideOriginal
              ? ""
              : `?original=${showOriginalReport}${
                  selectedTenant?._id ? `&id=${selectedTenant._id}` : ""
                }`
          }`,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        const data = response.data;
        setPreviewUrl(data?.url);
        triggerToast("Report Ready For Download", "success");
      } catch (error) {
        console.log(error);
        triggerToast("Preview could not be loaded! Please try again!", "error");
      }
      setPreviewLoading(false);
    },
    [report, token, triggerToast, selectedTenant]
  );

  const [sideMenuOpen, setSidemenuOpen] = useState(false);

  useEffect(() => {
    const table = $(tableRef.current).DataTable({
      dom: 'lf<"data-table-wrapper"t>ip',
      data: data,
      columns: columns,
      order:
        type === "Tenants"
          ? [[6, "desc"]]
          : type === "Properties"
          ? [[1, "asc"]]
          : type === "Reports"
          ? [[2, "desc"]]
          : type === "Templates"
          ? [[1, "desc"]]
          : [[0, "asc"]],
      createdRow: function (row, data, dataIndex) {
        if (data.isPriority) {
          $(row).addClass("priority");
        }
      },
      columnDefs: [
        {
          targets: [type === "Properties" ? 0 : null],
          width: 60,
          className: "center",
          createdCell: (td, cellData, rowData) => {
            if (cellData.length === 0) {
              return ReactDOM.render(<ApartmentRounded color="red" />, td);
            }
            return ReactDOM.render(
              <img
                onClick={() => handleOpen(cellData)}
                src={cellData}
                alt="property"
                className="border border-[#eeeeee] rounded-md cursor-pointer bg-black"
              />,
              td
            );
          },
        },
        {
          targets: [type === "Reports" ? 0 : null],
          width: 60,
          className: "center",
          createdCell: (td, cellData, rowData) => {
            if (cellData.length === 0) {
              return ReactDOM.render(<ApartmentRounded color="red" />, td);
            }
            return ReactDOM.render(
              <img
                onClick={() => handleOpen(cellData)}
                src={cellData}
                alt="property"
                className="border border-[#eeeeee] rounded-md cursor-pointer bg-black"
              />,
              td
            );
          },
        },
        {
          targets: [
            type === "Reports" || type === "View Reports"
              ? columns.length - 2
              : null,
            type === "Edit_Tenants" && columns.length - 2,
          ],
          width: 100,
          className: "center",
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(
              <div
                id={rowData.id}
                className="flex items-center justify-center"
                style={{
                  background:
                    COLOR_SCHEMES[cellData] && COLOR_SCHEMES[cellData].bg,
                  border: `1px solid ${
                    COLOR_SCHEMES[cellData] && COLOR_SCHEMES[cellData].text
                  }`,
                  borderRadius: "7px",
                  color:
                    COLOR_SCHEMES[cellData] && COLOR_SCHEMES[cellData].text,
                  padding: "2px",
                  fontWeight: 600,
                }}
              >
                {cellData === "waiting_to_be_signed" ? "waiting" : cellData}
              </div>,
              td
            ),
        },
        {
          targets: [
            type !== "Notes" &&
              type !== "Tenants" &&
              type !== "Edit_Tenants" &&
              type !== "Report Notes" &&
              type !== "Settings" &&
              columns.length - 1,
          ],
          className: "center dt-control",
          width: 60,
          orderable: false,
          data: null,
          defaultContent: "",
          createdCell: (td, cellData, rowData, dataIndex) =>
            ReactDOM.render(
              <Popover
                placement={"bottom-start"}
                dismiss={{
                  outsidePress: true,
                  outsidePressEvent: "pointerdown",
                }}
              >
                <PopoverHandler>
                  <MoreVertIcon fontSize={"small"} />
                </PopoverHandler>
                <PopoverContent className="shadow-md">
                  <div
                    id={`menu_${dataIndex}`}
                    style={{
                      minWidth: "160px",
                      fontSize: 14,
                    }}
                  >
                    {user?.role === "customer" ? null : (
                      <span
                        className="cursor-pointer text-start hover:text-coolBlue font-medium mb-3 block"
                        onClick={() => {
                          navigate(`/${cellData.route.split("/")[1]}/edit`, {
                            state: { item: cellData.item, isEdit: true },
                          });
                        }}
                      >
                        <EditOutlined
                          fontSize={"small"}
                          className={"text-coolBlue mr-2"}
                        />
                        Edit
                      </span>
                    )}

                    {type === "Reports" && user?.role === "customer" ? (
                      ""
                    ) : (
                      <span
                        className="cursor-pointer text-start hover:text-coolBlue font-medium mb-3 block"
                        onClick={() => {
                          navigate(cellData.route, {
                            state: { item: cellData.item, isEdit: false },
                          });
                        }}
                      >
                        <VisibilityOutlined
                          fontSize={"small"}
                          className={"text-coolBlue mr-2"}
                        />
                        View{" "}
                        {type === "Reports" || type === "Templates"
                          ? type.slice(0, type.length - 1)
                          : ""}
                      </span>
                    )}
                    {!(
                      cellData.route.includes("tenants") ||
                      cellData.route.includes("users") ||
                      cellData.route.includes("branches") ||
                      cellData.route.includes("templates") ||
                      cellData.route.includes("customers")
                    ) && (
                      <span
                        className="cursor-pointer text-start hover:text-coolBlue font-medium mb-3 block"
                        onClick={() => {
                          getTenancyItem(cellData?.item?._id)
                            ? navigate(`/tenants/edit`, {
                                state: {
                                  item: getTenancyItem(cellData?.item?._id),
                                  isEdit: false,
                                },
                              })
                            : navigate(`/tenants/add`, {
                                state: {
                                  propertyAddress:
                                    cellData?.item?.property_id?.address,
                                  referenceNo: cellData?.item?.ref_number,
                                  warning: true,
                                },
                              });
                        }}
                      >
                        <HolidayVillageOutlined
                          fontSize={"small"}
                          className={"text-coolBlue mr-2"}
                        />
                        {cellData.route.includes("properties")
                          ? "Tenants"
                          : "View Tenancy"}
                      </span>
                    )}
                    {cellData.route.includes("report") && (
                      <>
                        <div className="flex gap-1 items-center">
                          <span
                            className="cursor-pointer text-start hover:text-coolBlue font-medium mb-3 block"
                            onClick={() => {
                              cellData.item?.report_type === "Inventory Report"
                                ? handleSetReport(cellData.item?._id, "preview")
                                : window.open(
                                    `/view-pdf/${cellData?.item?._id}`,
                                    "_blank"
                                  );
                            }}
                          >
                            <PreviewOutlined
                              fontSize={"small"}
                              className={"text-coolBlue mr-2"}
                            />
                            Preview
                          </span>
                        </div>
                        <span
                          className="cursor-pointer text-start hover:text-coolBlue font-medium mb-3 block"
                          onClick={() => {
                            cellData.item?.report_type === "Inventory Report"
                              ? handleSetReport(cellData.item?._id, "download")
                              : previewReport(cellData.item?._id, true);
                          }}
                        >
                          <DownloadOutlined
                            fontSize={"small"}
                            className={"text-coolBlue mr-2"}
                          />
                          Download
                        </span>
                        {user?.role !== "customer" && (
                          <span
                            className="cursor-pointer text-start hover:text-coolBlue font-medium mb-3 block"
                            onClick={() => {
                              setCloneReportPopupOpen(true);
                              setCurrentReport(cellData?.item);
                            }}
                          >
                            <FileCopyOutlined
                              fontSize={"small"}
                              className={"text-coolBlue mr-2"}
                            />
                            Clone Report
                          </span>
                        )}
                        {(cellData?.item?.status === "completed" ||
                          cellData?.item?.status === "approved" ||
                          cellData?.item?.status === "signed") && (
                          <span
                            className="cursor-pointer text-start hover:text-coolBlue font-medium mb-3 block"
                            onClick={() => {
                              setCurrentReportId(cellData.item?._id);
                              setEmailOpen(true);
                              handleEmailOpen(cellData?.item?._id);
                            }}
                          >
                            <MailOutlined
                              fontSize={"small"}
                              className={"text-coolBlue mr-2"}
                            />
                            Email
                          </span>
                        )}
                        {cellData?.item?.status === "completed" &&
                          cellData?.item?.report_type?.toLowerCase() ===
                            "inventory report" && (
                            <span
                              className="cursor-pointer text-start hover:text-coolBlue font-medium mb-3 block"
                              onClick={() => {
                                setCurrentReportId(cellData.item?._id);
                                setSignatureOpen(true);
                                handleEmailOpen(cellData?.item?._id);
                              }}
                            >
                              <DriveFileRenameOutlineOutlined
                                fontSize={"small"}
                                className={"text-coolBlue mr-2"}
                              />
                              Send for Signature
                            </span>
                          )}
                        {/* <span
                          className="cursor-pointer text-start hover:text-coolBlue font-medium"
                          onClick={() => { }}
                        >
                          <FormatListBulletedOutlined
                            fontSize={"small"}
                            className={"text-coolBlue mr-2"}
                          />
                          View Logs
                        </span> */}

                        <span
                          className="cursor-pointer text-start hover:text-coolBlue font-medium mb-3 block"
                          onClick={() => {
                            navigate(`/reports/gallery/${cellData.item?._id}`);
                          }}
                        >
                          <InsertPhotoOutlined
                            fontSize={"small"}
                            className={"text-coolBlue mr-2"}
                          />
                          Gallery
                        </span>
                      </>
                    )}
                    {user?.role === "customer" ||
                    (user?.role === "clerk" &&
                      (cellData.route.includes("customer") ||
                        cellData.route.includes("properties"))) ? null : (
                      <>
                        <span
                          className="cursor-pointer text-start hover:text-coolBlue font-medium block"
                          onClick={() =>
                            handleItemDelete(
                              cellData?.item?._id,
                              cellData.route.split("/")[1]
                            )
                          }
                        >
                          <DeleteOutlined
                            fontSize={"small"}
                            className={"text-coolBlue mr-2"}
                          />
                          Delete
                        </span>
                        {cellData.route.includes("templates") && (
                          <span
                            className="cursor-pointer text-start hover:text-coolBlue mt-3 font-medium block"
                            onClick={() =>
                              duplicateTemplate(cellData?.item?._id)
                            }
                          >
                            <FileCopyOutlined
                              fontSize={"small"}
                              className={"text-coolBlue mr-2"}
                            />
                            Duplicate
                          </span>
                        )}
                      </>
                    )}
                  </div>
                </PopoverContent>
              </Popover>,
              td
            ),
        },
        {
          targets: [type === "Edit_Tenants" && 1, type === "Edit_Tenants" && 2],
          width: 400,
          createdCell: (td, cellData, rowData) => {
            ReactDOM.render(
              // <div className="flex items-center justify-center" style={{ color: COLOR_SCHEMES[rowData.status]?.text }}>
              //   {cellData}
              // </div>,
              <div className="flex items-center justify-center">
                {cellData}
              </div>,
              td
            );
          },
        },
        {
          targets: [
            (type === "Reports" || type === "View Reports") &&
              columns.length - 3,
          ],
          width: 70,
          createdCell: (td, cellData, rowData) => {
            ReactDOM.render(
              <div
                className="cursor-pointer text-center"
                onClick={() => {
                  getTenancyItem(
                    allReports.filter(
                      (r) =>
                        r?.ref_number === rowData?.referenceNo ||
                        r?.ref_number ===
                          rowData?.viewProperty?.item?.ref_number
                    )[0]?._id
                  )
                    ? navigate(`/tenants/edit`, {
                        state: {
                          item: getTenancyItem(rowData?.viewReport?.item?._id),
                          isEdit: false,
                        },
                      })
                    : navigate(`/tenants/add`, {
                        state: {
                          propertyAddress:
                            rowData?.viewReport?.item?.property_id?.address,
                          referenceNo: rowData?.viewReport?.item?.ref_number,
                          warning: true,
                        },
                      });
                }}
              >
                {cellData}
              </div>,
              td
            );
          },
        },
        {
          targets: [type === "Report Notes" && columns.length - 1],
          width: 100,
          className: "center",
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(
              <div
                id={rowData.id}
                onClick={() => {
                  cellData.func(cellData.item);
                }}
                style={{
                  color: "#212121",
                  cursor: "pointer",
                }}
              >
                <Edit fontSize="small" color="primary" />
              </div>,
              td
            ),
        },
        {
          targets: [type === "Notes" && columns.length - 1],
          width: 100,
          className: "center",
          orderable: false,
          data: null,
          defaultContent: "",
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(
              <div className="flex justify-start text-[#212121] gap-2">
                <Tooltip title="Delete">
                  <DeleteForeverRounded
                    fontSize="small"
                    color="error"
                    className="cursor-pointer"
                    onClick={deleteNote && (() => deleteNote(rowData))}
                  />
                </Tooltip>
              </div>,
              td
            ),
        },
        {
          targets: [type === "Edit_Tenants" && columns.length - 1],
          width: 60,
          className: "center dt-control",
          orderable: false,
          data: null,
          defaultContent: "",
          createdCell: (td, cellData, rowData, dataIndex) =>
            ReactDOM.render(
              <Popover
                placement={"bottom-start"}
                dismiss={{
                  outsidePress: true,
                  outsidePressEvent: "pointerdown",
                }}
              >
                <PopoverHandler>
                  <MoreVertIcon fontSize={"small"} />
                </PopoverHandler>
                <PopoverContent className="shadow-md">
                  <div
                    id={`menu_${dataIndex}`}
                    style={{
                      minWidth: "160px",
                      fontSize: 14,
                    }}
                  >
                    <span
                      className="cursor-pointer text-start hover:text-coolBlue font-medium mb-3 block"
                      onClick={() => {
                        rowData?.reportType === "Inventory Report"
                          ? (() => {
                              setSelectedTenant({ _id: rowData?.tenantId });
                              handleSetReport(rowData?.reportId, "download");
                            })()
                          : previewReport(rowData?.reportId, true);
                      }}
                    >
                      <DownloadOutlined
                        fontSize={"small"}
                        className={"text-coolBlue mr-2"}
                      />
                      Download Report
                    </span>
                    <span
                      className="cursor-pointer text-start hover:text-coolBlue font-medium mb-3 block"
                      onClick={() => {
                        rowData.reportType === "Inventory Report"
                          ? (() => {
                              setSelectedTenant({ _id: rowData?.tenantId });
                              handleSetReport(rowData?.reportId, "preview");
                            })()
                          : window.open(
                              `/view-pdf/${rowData?.reportId}`,
                              "_blank"
                            );
                      }}
                    >
                      <VisibilityOutlined
                        fontSize={"small"}
                        className={"text-coolBlue mr-2"}
                      />
                      Preview Report
                    </span>
                    <span
                      className="cursor-pointer text-start hover:text-coolBlue font-medium mb-3 block"
                      onClick={onTenantEdit && (() => onTenantEdit(cellData))}
                    >
                      <EditOutlined
                        fontSize={"small"}
                        className={"text-coolBlue mr-2"}
                      />
                      Edit
                    </span>
                    <span
                      className="cursor-pointer text-start hover:text-coolBlue font-medium mb-3 block"
                      onClick={
                        onTenantDelete && (() => onTenantDelete(cellData))
                      }
                    >
                      <DeleteOutlined
                        fontSize={"small"}
                        className={"text-coolBlue mr-2"}
                      />
                      Delete
                    </span>
                    {rowData.status === "sent" && (
                      <span
                        className="cursor-pointer text-start hover:text-coolBlue font-medium mb-3 block"
                        onClick={() =>
                          handleSendSigature(rowData, rowData?.reportId)
                        }
                      >
                        <Send
                          fontSize={"small"}
                          className={"text-coolBlue mr-2"}
                        />
                        Resend Signature Email
                      </span>
                    )}
                    {rowData.status === "feedback" && (
                      <a
                        href={
                          rowData.tenancyType === "HMO"
                            ? `${
                                window.location.pathname.split("/")[0]
                              }/inspect/${rowData.reportId}?id=${
                                rowData.tenantId
                              }`
                            : `${
                                window.location.pathname.split("/")[0]
                              }/inspect/${rowData.reportId}?id=${
                                rowData.tenantId
                              }`
                        }
                        target="_blank"
                      >
                        <span
                          className="cursor-pointer text-start hover:text-coolBlue font-medium block"
                          onClick={() =>
                            handleSendSigature(rowData, rowData?.reportId)
                          }
                        >
                          <Visibility
                            fontSize={"small"}
                            className={"text-coolBlue mr-2"}
                            onClick={() => handleTenantFeedback(rowData)}
                          />
                          Inspector Comments
                        </span>
                      </a>
                    )}
                  </div>
                </PopoverContent>
              </Popover>,
              td
            ),
        },
        {
          targets: [(type === "Templates" || type === "View Reports") && 1],
          width: 100,
          className: "center",
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(
              <div
                id={rowData.id}
                onClick={() => {
                  cellData.func(cellData.item);
                }}
                style={{
                  color: "#212121",
                  cursor: "pointer",
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {
                  new Date(cellData)
                    .toLocaleString("en-GB", { timeZone: "UTC" })
                    .split(",")[0]
                }
              </div>,
              td
            ),
        },
        {
          // date column in reports table
          targets: [type === "Reports" && 2],
          width: 100,
          className: "dt-head-left text-left",
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(
              <div
                id={rowData.id}
                onClick={() => {
                  cellData.func(cellData.item);
                }}
                style={{
                  color: "#212121",
                  cursor: "pointer",
                  textAlign: "left",
                  display: "flex",
                }}
              >
                {
                  new Date(cellData)
                    .toLocaleString("en-GB", { timeZone: "UTC" })
                    .split(",")[0]
                }
              </div>,
              td
            ),
        },
        {
          targets: [type === "Users" && columns.length - 2],
          width: 100,
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(
              <div
                id={rowData.id}
                style={{
                  color: "#212121",
                  paddingLeft: "10px",
                }}
              >
                {
                  new Date(cellData)
                    .toLocaleString("en-GB", { timeZone: "UTC" })
                    .split(",")[0]
                }
              </div>,
              td
            ),
        },
        {
          targets: [
            type === "Branches" && columns.length - 2,
            type === "Branches" && columns.length - 3,
          ],
          width: 100,
          className: "center",
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(
              <div
                id={rowData.id}
                onClick={() => {
                  cellData.func(cellData.item);
                }}
                style={{
                  color: "#212121",
                  cursor: "pointer",
                  display: "flex",
                }}
              >
                {
                  new Date(cellData)
                    .toLocaleString("en-GB", { timeZone: "UTC" })
                    .split(",")[0]
                }
              </div>,
              td
            ),
        },
        { targets: [type === "Edit_Tenants" && 0], className: "hidden" },
        {
          targets: [type === "Settings" && columns.length - 1],
          width: 180,
          className: "center",
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(
              <div
                id={rowData.id}
                className="flex gap-2"
                style={{
                  color: "#212121",
                  cursor: "pointer",
                }}
              >
                <EditRounded
                  color="primary"
                  onClick={() =>
                    cellData.handleEditDelete(cellData.item, "edit")
                  }
                />
                <DeleteForeverRounded
                  color="error"
                  onClick={() =>
                    cellData.handleEditDelete(cellData.item, "delete")
                  }
                />
              </div>,
              td
            ),
        },
        {
          targets: [type === "Templates" && 2],
          width: 100,
          className: "left",
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(
              <span className="text-left flex">{cellData}</span>,
              td
            ),
        },
        {
          // bedrooms column in properties table
          targets: [type === "Properties" && columns.length - 5],
          width: 100,
          className: "text-left",
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(
              <span className="text-left flex">{cellData}</span>,
              td
            ),
        },
        {
          // bathrooms column in properties table
          targets: [type === "Properties" && columns.length - 4],
          width: 100,
          className: "text-left",
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(
              <span className="text-left flex">{cellData}</span>,
              td
            ),
        },
        {
          // property type column in properties table
          targets: [type === "Properties" && columns.length - 6],
          width: 150,
          className: "dt-head-left text-left",
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(
              <span className="text-left flex">{cellData}</span>,
              td
            ),
        },
        {
          // inspector column in reports table
          targets: [type === "Reports" && columns.length - 4],
          width: 140,
          className: "dt-head-left text-left",
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(
              <span className="text-start flex">{cellData}</span>,
              td
            ),
        },
        {
          // address in customers table
          targets: [type === "Customers" && 1],
          className: "dt-head-left text-left",
          width: 200,
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(
              <span className="flex justify-start items-start">
                {cellData}
              </span>,
              td
            ),
        },
        {
          // town in customers table
          targets: [type === "Customers" && 2],
          className: "dt-head-left text-left",
          width: 160,
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(
              <span className="flex justify-start items-start">
                {cellData}
              </span>,
              td
            ),
        },
        {
          // telephone no in customers table
          targets: [type === "Customers" && 5],
          className: "dt-head-left text-left",
          width: 120,
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(
              <span className="flex justify-start items-start">
                {cellData}
              </span>,
              td
            ),
        },
        {
          // customer column in customers table
          targets: [type === "Customers" && 0],
          className: "dt-head-left text-left",
          width: 160,
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(
              <span className="flex justify-start items-start">
                {cellData}
              </span>,
              td
            ),
        },
        {
          // customer column in properties table
          targets: [type === "Properties" && columns.length - 3],
          className: "dt-head-left text-left",
          width: 160,
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(
              <span className="flex justify-start items-start">
                {cellData}
              </span>,
              td
            ),
        },
        {
          // address in reports table
          targets: [type === "Reports" && 3],
          className: "dt-head-left text-left",
          width: 160,
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(
              <span className="flex justify-start items-start">
                {cellData}
              </span>,
              td
            ),
        },
        {
          // ref no in reports table
          targets: [type === "Reports" && 1],
          className: "dt-head-left text-left",
          width: 100,
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(
              <span className="flex justify-start items-start break-all">
                {cellData}
              </span>,
              td
            ),
        },
        {
          //postcode in customers table
          targets: [type === "Customers" && 3],
          className: "dt-head-left text-left",
          width: 100,
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(
              <span className="flex justify-start items-start">
                {cellData}
              </span>,
              td
            ),
        },
        {
          // postcode column in properties table
          targets: [type === "Properties" && columns.length - 7],
          className: "dt-head-left text-left",
          width: 120,
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(
              <span className="flex justify-start items-start">
                {cellData}
              </span>,
              td
            ),
        },
        {
          // post code column in reports table
          targets: [type === "Reports" && columns.length - 7],
          className: "dt-head-left text-left",
          width: 80,
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(
              <span className="flex justify-start items-start">
                {cellData}
              </span>,
              td
            ),
        },
        {
          // report type column in reports table
          targets: [type === "Reports" && 7],
          className: "dt-head-left text-left",

          width: 120,
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(
              <span className="flex justify-start items-start">
                {cellData}
              </span>,
              td
            ),
        },
        {
          // customer column in reports table
          targets: [type === "Reports" && 6],
          className: "dt-head-left text-left",
          width: 150,
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(
              <span className="flex justify-start items-start">
                {cellData}
              </span>,
              td
            ),
        },
        {
          // town in reports table
          targets: [type === "Reports" && 4],
          className: "dt-head-left text-left",
          width: 120,
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(
              <span className="flex justify-start items-start">
                {cellData}
              </span>,
              td
            ),
        },
        {
          targets: [
            type === "Templates" && 0,
            type === "Templates" && 3,
            type === "Templates" && 4,
          ],
          className: "dt-head-left text-left",
          width: 220,
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(
              <span className="flex justify-start items-start">
                {cellData}
              </span>,
              td
            ),
        },
        {
          targets: [type === "Templates" && 5],
          className: "dt-head-left text-left",
          width: 220,
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(
              <span className="flex justify-start items-start">
                {
                  new Date(cellData)
                    .toLocaleString("en-GB", { timeZone: "UTC" })
                    .split(",")[0]
                }
              </span>,
              td
            ),
        },

        {
          // town column in properties table
          targets: [type === "Properties" && 2],
          className: "dt-head-left text-left",
          width: 180,
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(
              <span className="flex justify-start items-start">
                {cellData}
              </span>,
              td
            ),
        },
        {
          // address column in properties table
          targets: [type === "Properties" && 1],
          className: "dt-head-left text-left",
          width: 220,
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(
              <span className="flex justify-start items-start">
                {cellData}
              </span>,
              td
            ),
        },
        {
          targets: [
            type === "Users" && 0,
            type === "Users" && 1,
            type === "Users" && 4,
          ],
          className: "dt-head-left text-left",
          width: 320,
          defaultContent: "",
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(
              <span className="flex justify-start items-start">
                {cellData}
              </span>,
              td
            ),
        },
        {
          targets: [type === "Users" && 2],
          className: "dt-head-left text-left",
          width: 100,
          defaultContent: "",
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(
              <span className="flex justify-start items-start">
                {cellData}
              </span>,
              td
            ),
        },
        {
          targets: [type === "Users" && 3],
          className: "dt-head-left text-left",
          width: 150,
          defaultContent: "",
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(
              <span className="flex justify-start items-start">
                {rowData?.branch_id?.name}
              </span>,
              td
            ),
        },
        {
          targets: [
            type === "Branches" && 0,
            type === "Branches" && 1,
            type === "Branches" && 3,
            type === "Branches" && 4,
            type === "Branches" && 5,
          ],
          className: "dt-head-left text-left",
          width: 320,
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(
              <span className="flex justify-start items-start">
                {cellData}
              </span>,
              td
            ),
        },
        {
          targets: [type === "Branches" && 2],
          className: "dt-head-left text-left",
          width: 320,
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(
              <span className="flex justify-start items-start">
                {cellData || "-"}
              </span>,
              td
            ),
        },
        {
          // email in customers table
          targets: [type === "Customers" && 4],
          className: "dt-head-left text-left",
          width: 240,
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(
              <span className="flex justify-start items-start overflow-hidden w-[220px]">
                {cellData}
              </span>,
              td
            ),
        },
        {
          // reports column in customers table
          targets: [type === "Customers" && columns.length - 2],
          width: 100,
          className: "text-left",
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(
              <span
                className={`text-left flex ${
                  type === "Properties" ? "cursor-pointer" : ""
                }`}
                onClick={() => {
                  if (type === "Properties") {
                    navigate("/properties/view", {
                      state: { item: rowData.viewProperty.item, isEdit: false },
                    });
                  }
                }}
              >
                {cellData}
              </span>,
              td
            ),
        },
        {
          // reports column in properties table
          targets: [type === "Properties" && columns.length - 2],
          width: 100,
          className: "text-left",
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(
              <span
                className={`text-center flex ${
                  type === "Properties" ? "cursor-pointer" : ""
                }`}
                onClick={() => {
                  if (type === "Properties") {
                    navigate("/properties/view", {
                      state: { item: rowData.viewProperty.item, isEdit: false },
                    });
                  }
                }}
              >
                {cellData}
              </span>,
              td
            ),
        },
        {
          // properties column in customers table
          targets: [type === "Customers" && columns.length - 3],
          width: 100,
          className: "text-left",
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(
              <span className="text-left flex">{cellData}</span>,
              td
            ),
        },
        // Tenants table
        {
          //Name column in tenants table
          targets: [type === "Tenants" && 0],
          width: 100,
          className: "text-left",
          createdCell: (td, cellData, rowData) => {
            ReactDOM.render(
              <div className="flex flex-col items-start gap-1 flex-wrap overflow-x-auto">
                {cellData?.length > 0 ? cellData[0].name : "-"}
              </div>,
              td
            );
          },
        },
        {
          // address column in tenants table
          targets: [type === "Tenants" && 1],
          width: 120,
          className: "text-left",

          createdCell: (td, cellData, rowData) => {
            ReactDOM.render(
              <div className="flex flex-col items-start gap-1 flex-wrap overflow-x-auto">
                {cellData}
              </div>,
              td
            );
          },
        },
        {
          // town column in tenants table
          targets: [type === "Tenants" && 2],
          width: 80,
          className: "text-left",

          createdCell: (td, cellData, rowData) => {
            ReactDOM.render(
              <div className="flex flex-col items-start gap-1 flex-wrap overflow-x-auto">
                {cellData}
              </div>,
              td
            );
          },
        },
        {
          // postcode column in tenants table
          targets: [type === "Tenants" && 3],
          width: 60,
          className: "text-left",

          createdCell: (td, cellData, rowData) => {
            ReactDOM.render(
              <div className="flex flex-col w-full items-start gap-1 flex-wrap overflow-x-auto">
                {cellData}
              </div>,
              td
            );
          },
        },
        {
          // type column in tenants table
          targets: [type === "Tenants" && 4],
          width: 60,
          className: "text-left",

          createdCell: (td, cellData, rowData) => {
            ReactDOM.render(
              <div className="flex flex-col items-start gap-1 flex-wrap overflow-x-auto">
                {cellData}
              </div>,
              td
            );
          },
        },
        {
          // report ref column in tenants table
          targets: [type === "Tenants" && 5],
          width: 80,
          className: "text-left",

          createdCell: (td, cellData, rowData) => {
            ReactDOM.render(
              <div className="flex flex-col items-start gap-1 flex-wrap overflow-x-auto">
                {cellData}
              </div>,
              td
            );
          },
        },
        {
          // date column in tenants table
          targets: [type === "Tenants" && 6],
          width: 100,
          className: "text-left",

          createdCell: (td, cellData, rowData) => {
            ReactDOM.render(
              <div className="flex flex-col items-start gap-1 flex-wrap overflow-x-auto">
                {
                  new Date(cellData)
                    .toLocaleString("en-GB", { timeZone: "UTC" })
                    .split(",")[0]
                }
              </div>,
              td
            );
          },
        },
        {
          // status column in tenants table
          targets: [type === "Tenants" && 7],
          className: "text-left",

          width: 80,
          createdCell: (td, cellData, rowData) => {
            const status = cellData?.length > 0 ? cellData[0].status : "";
            ReactDOM.render(
              status && (
                <div
                  id={rowData.id}
                  className="flex items-center justify-center"
                  style={{
                    background:
                      COLOR_SCHEMES[status] && COLOR_SCHEMES[status].bg,
                    border: `1px solid ${
                      COLOR_SCHEMES[status] && COLOR_SCHEMES[status].text
                    }`,
                    borderRadius: "7px",
                    color: COLOR_SCHEMES[status] && COLOR_SCHEMES[status].text,
                    padding: "2px",
                    fontWeight: 600,
                  }}
                >
                  {status === "waiting_to_be_signed" ? "waiting" : status}
                </div>
              ),
              td
            );
          },
        },
        {
          // more options column in tenants table
          targets: [type === "Tenants" && 8],
          className: "center dt-control",
          width: 30,
          orderable: false,
          data: null,
          defaultContent: "",
          createdCell: (td, cellData, rowData, dataIndex) =>
            ReactDOM.render(
              <Popover
                placement={"bottom-start"}
                dismiss={{
                  outsidePress: true,
                  outsidePressEvent: "pointerdown",
                }}
              >
                <PopoverHandler>
                  <MoreVertIcon fontSize={"small"} />
                </PopoverHandler>
                <PopoverContent className="shadow-md">
                  <div
                    id={`menu_${dataIndex}`}
                    style={{
                      minWidth: "160px",
                      fontSize: 14,
                    }}
                  >
                    <span
                      className="cursor-pointer text-start hover:text-coolBlue font-medium mb-3 block"
                      onClick={() => {
                        navigate(`/${cellData.route.split("/")[1]}/edit`, {
                          state: { item: cellData.item, isEdit: true },
                        });
                      }}
                    >
                      <EditOutlined
                        fontSize={"small"}
                        className={"text-coolBlue mr-2"}
                      />
                      Edit
                    </span>
                    <span
                      className="cursor-pointer text-start hover:text-coolBlue font-medium mb-3 block"
                      onClick={() => {
                        navigate(cellData.route, {
                          state: { item: cellData.item, isEdit: false },
                        });
                      }}
                    >
                      <VisibilityOutlined
                        fontSize={"small"}
                        className={"text-coolBlue mr-2"}
                      />
                      View Tenant
                    </span>
                    <span
                      className="cursor-pointer text-start hover:text-coolBlue font-medium mb-3 block"
                      onClick={() => {
                        const tenantReports = allReports.filter(
                          (report) =>
                            report._id === cellData?.item?.report_id?._id
                        );
                        if (tenantReports?.length > 0) {
                          navigate("/reports/response", {
                            state: { item: tenantReports[0], isEdit: false },
                          });
                        }
                      }}
                    >
                      <VisibilityOutlined
                        fontSize={"small"}
                        className={"text-coolBlue mr-2"}
                      />
                      View Report
                    </span>
                    <span
                      className="cursor-pointer text-start hover:text-coolBlue font-medium block"
                      onClick={() =>
                        handleItemDelete(cellData?.item?._id, "tenants")
                      }
                    >
                      <DeleteOutlined
                        fontSize={"small"}
                        className={"text-coolBlue mr-2"}
                      />
                      Delete
                    </span>
                  </div>
                </PopoverContent>
              </Popover>,
              td
            ),
        },
      ],
      scrollY: `${ht / 2}`,
      destroy: true, // I think some clean up is happening here
    });
    table.on("click", "td.dt-control", function (e) {
      let tr = e.target.closest("tr");
      let row = table.row(tr);
      const menu = document.getElementById(`menu_${row[0][0]}`);
      if (menu) {
        if (menu.style.display === "flex") {
          row.child.hide();
          menu.style.display = "none";
        } else {
          row.child("").show();
          let menus = document.querySelectorAll('[id^="menu"]');
          menus.forEach((item) => (item.style.display = "none"));
          menu.style.display = "flex";
          menu.style.flexDirection = "column";
          row.child.hide();
        }
      }
    });
    // // Extra step to do extra clean-up.
    return function () {
      table.destroy();
    };
  }, [columns, data, ht, navigate, type, user]);

  const reloadTableData = (data) => {
    const table = $(".data-table-wrapper").find("table").DataTable();
    table.clear();
    table.rows.add(data);
    table.draw();
  };

  useEffect(() => {
    reloadTableData(data);
  }, [data]);

  const handleEmailOpen = async (id) => {
    setEmailLoading(true);
    try {
      const response = await axios.get(
        `${API_URL}/account/report/${id}/email`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      const data = response.data;
      setTenants(data?.tenant);
      setContacts(data?.customer);
      triggerToast("Contacts and Tenants loaded successfully", "success");
    } catch (error) {
      console.log(error);
    } finally {
      setEmailLoading(false);
    }
  };

  const [selectedContacts, setSelectedContacts] = useState([]);
  const [selectedTenants, setSelectedTenants] = useState([]);
  const [inputEmail, setInputEmail] = useState("");
  const [customEmails, setCustomEmails] = useState([]);

  const handleCustomEmailAdd = () => {
    if (!validateEmail(inputEmail)) {
      triggerToast("Please enter a valid email", "warning");
      return;
    }
    setCustomEmails((customEmails) => [...customEmails, inputEmail]);
    setInputEmail("");
  };

  const handleCustomEmailDelete = (email) => {
    setCustomEmails(customEmails.filter((e) => e !== email));
  };

  const canSendEmail = () => {
    return (
      customEmails?.length > 0 ||
      selectedTenants?.length > 0 ||
      selectedContacts?.length > 0
    );
  };

  const handleSendEmail = async () => {
    let checkedItems = [];
    const filteredContacts = contacts.filter((item) =>
      selectedContacts.includes(item.email)
    );
    const filteredTenants = tenants.filter((item) =>
      selectedTenants.includes(item.email)
    );
    checkedItems = [...filteredContacts, ...filteredTenants];
    customEmails.map((email) => checkedItems.push({ email: email }));
    try {
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${API_URL}/account/report/${currentReportId}/email`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: JSON.stringify({ payload: checkedItems }),
      };
      const res = await axios.request(config);
      triggerToast("Email sent successfully", "success");
      handleEmailClose();
    } catch (error) {
      triggerToast(error?.response?.data?.message, "error");
    }
  };
  const handleSendSigature = async (tenant, reportId) => {
    let checkedItems = [];
    const filteredTenants = tenants.filter((item) =>
      selectedTenants.includes(item.email)
    );
    checkedItems = [...filteredTenants];
    if (tenant) checkedItems.push(tenant);
    try {
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${API_URL}/account/report/${
          currentReportId || reportId
        }/signature`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: JSON.stringify({ payload: checkedItems }),
      };
      const res = await axios.request(config);
      triggerToast("Signature sent successfully", "success");
      setSignatureOpen(false);
    } catch (error) {
      triggerToast(error?.response?.data?.message, "error");
    }
  };

  const handleEmailClose = () => {
    setContacts([]);
    setTenants([]);
    setCurrentReportId("");
    setSelectedContacts([]);
    setSelectedTenants([]);
    setInputEmail("");
    setCustomEmails([]);
    setEmailOpen(false);
  };

  const handleCheckboxClick = (type, item) => {
    if (type === "contact") {
      if (selectedContacts.includes(item)) {
        setSelectedContacts(selectedContacts.filter((i) => i !== item));
      } else {
        setSelectedContacts([...selectedContacts, item]);
      }
    } else if (type === "tenant") {
      if (selectedTenants.includes(item)) {
        setSelectedTenants(selectedTenants.filter((i) => i !== item));
      } else {
        setSelectedTenants([...selectedTenants, item]);
      }
    }
  };

  return (
    <div className="w-full">
      <PreviewModal
        popupOpen={popupOpen}
        handlePopupClose={handlePopupClose}
        pdfLoading={pdfLoading}
        previewType={previewType}
        isTenancyTypeHMO={isTenancyTypeHMO}
        tenants={tenants}
        selectedTenant={selectedTenant}
        showOriginalReport={showOriginalReport}
        setShowOriginalReport={setShowOriginalReport}
        setSelectedTenant={setSelectedTenant}
        report={report}
        handlePdfPreview={handlePdfPreview}
      />
      <Modal
        open={cloneReportPopupOpen}
        onClose={handleCloneReportPopupClose}
        className="w-screen h-screen flex items-center justify-center"
      >
        <div className="bg-white w-fit h-fit p-8 pt-6 flex flex-col gap-8 rounded-md">
          <span className="text-[#010101 text-[22px] font-semibold">
            Clone Report {currentReport && currentReport?.ref_number}
          </span>
          <div className="flex flex-col gap-3">
            {cloneReportData?.map((data) => (
              <div
                key={data?.name}
                className={`${
                  data[
                    currentReport?.report_type?.toLowerCase().replace(/\s/g, "")
                  ]
                    ? "flex"
                    : "hidden"
                } gap-16 items-center`}
              >
                <span className="font-medium text-base w-[160px]">
                  {data?.title}
                </span>
                <div className="flex gap-8 items-center">
                  <Checkbox
                    label="Content"
                    value={cloneReportFormData[data?.name]}
                    handleChange={(e) =>
                      setCloneReportFormData({
                        ...cloneReportFormData,
                        [data.name]: e.target.checked,
                      })
                    }
                  />
                  {data?.images && (
                    <Checkbox
                      label="Images"
                      value={cloneReportFormData[`${data?.name}_images`]}
                      handleChange={(e) =>
                        setCloneReportFormData({
                          ...cloneReportFormData,
                          [`${data?.name}_images`]: e.target.checked,
                        })
                      }
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
          <button
            className="primary-button mx-auto"
            onClick={handleCloneReport}
          >
            Clone
          </button>
        </div>
      </Modal>
      <Dialog open={previewPdfDialogOpen} onClose={handlePreviewPdfDialogClose}>
        {previewLoading ? (
          <div className="flex flex-col md:w-[500px] h-52 px-8 mb-4 overflow-hidden bg-white items-center">
            <h2 className="text-center text-black font-medium mt-4">
              Generating PDF...
            </h2>
            <div className="flex flex-1 items-center justify-center">
              <div className="w-[63px] h-[63px] border-4 border-coolBlue border-t-gray-200 animate-spin rounded-full"></div>
            </div>
          </div>
        ) : (
          <>
            <span className="text-md font-semibold text-center md:w-[500px] h-[80px] px-8 pt-8 mb-4">
              PDF loaded. Do you want to view it now?
            </span>
            <DialogActions
              sx={{
                display: "flex",
                justifyContent: "space-between",
                paddingX: "2rem",
                paddingBottom: "1rem",
              }}
            >
              <button
                className="secondary-button"
                onClick={handlePreviewPdfDialogClose}
              >
                Cancel
              </button>
              <a
                className={"primary-button"}
                href={previewUrl}
                target="_blank"
                rel="noreferrer"
                onClick={handlePreviewPdfDialogClose}
              >
                View
              </a>
            </DialogActions>
          </>
        )}
      </Dialog>
      <table
        className="display dt-responsive align"
        width="100%"
        id="table"
        ref={tableRef}
      ></table>
      <Modal
        open={emailOpen}
        onClose={handleEmailClose}
        className="flex justify-center items-center"
      >
        <div
          className={`flex flex-col ${
            emailLoading ? "items-center" : "items-start"
          } bg-white w-[767px] h-fit p-8 pt-6 gap-8 rounded-md`}
        >
          {emailLoading ? (
            <LoadingAnimation />
          ) : (
            <>
              <div className="flex w-full">
                <span className="flex-1 text-[#010101 text-[22px] font-semibold">
                  Who do you want to email this report to?
                </span>
                <button onClick={handleEmailClose} className="text-[#090909]">
                  <CloseRounded />
                </button>
              </div>
              <div className="flex gap-6 w-full flex-col">
                <span className="font-semibold">Contacts</span>
                <div className="grid grid-cols-3 gap-x-32 gap-y-8">
                  {contacts?.map((item, index) => (
                    <div className="flex gap-6 items-center">
                      <input
                        type="checkbox"
                        className={`w-4 h-4 bg-[#FEFEFF] ${
                          selectedContacts.includes(item.email)
                            ? ""
                            : "borderless-checkbox"
                        } shadow-sm shadow-gray-300`}
                        value={selectedContacts.includes(item.email)}
                        onClick={() =>
                          handleCheckboxClick("contact", item.email)
                        }
                      />
                      <span className="font-normal text-[16px]">
                        {item.name}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex gap-6 w-full flex-col">
                <span className="font-semibold">Tenants</span>
                <div className="grid gap-y-8 gap-x-32 grid-cols-3">
                  {tenants?.map((item, index) => (
                    <div className="flex gap-6 items-center">
                      <input
                        type="checkbox"
                        className={`w-4 h-4 bg-[#FEFEFF] ${
                          selectedTenants.includes(item.email)
                            ? ""
                            : "borderless-checkbox"
                        } shadow-sm shadow-gray-300`}
                        value={selectedTenants.includes(item.email)}
                        onClick={() =>
                          handleCheckboxClick("tenant", item.email)
                        }
                      />
                      <span className="font-normal text-[16px]">
                        {item.name}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
              <div className="w-full flex flex-col gap-3">
                <span className="font-semibold">Enter Custom Email</span>
                <div className="w-full flex gap-4 items-center">
                  <Input
                    value={inputEmail}
                    onChange={(e) => setInputEmail(e.target.value)}
                  />
                  <button
                    onClick={handleCustomEmailAdd}
                    className="p-2 bg-blue-500 hover:bg-blue-600 text-white rounded-lg flex items-center justify-center"
                  >
                    <Add className="w-5 h-5" />
                  </button>
                </div>
                <div className="flex flex-col gap-2">
                  {customEmails.map((email) => (
                    <div className="flex gap-2 items-center">
                      <span key={email} className="font-normal text-[16px]">
                        {email}
                      </span>
                      <DeleteForeverRounded
                        color="error"
                        className="cursor-pointer"
                        fontSize="small"
                        onClick={() => handleCustomEmailDelete(email)}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div className="w-full flex  ">
                <button
                  disabled={!canSendEmail()}
                  className={`text-sm font-medium border px-4 rounded-lg shadow-md w-[190px] py-4 ${
                    !canSendEmail()
                      ? "bg-gray-400 text-gray-600 cursor-not-allowed" // Disabled state styles
                      : "bg-coolBlue text-white" // Active state styles
                  }`}
                  onClick={handleSendEmail}
                >
                  Email Report
                </button>
              </div>
            </>
          )}
        </div>
      </Modal>
      <Modal
        open={signatureOpen}
        onClose={() => setSignatureOpen(false)}
        className="flex justify-center  items-center"
      >
        <div
          className={`flex flex-col ${
            emailLoading ? "items-center" : "items-start"
          } bg-white w-[767px] h-fit p-8 pt-6 gap-8 rounded-md`}
        >
          {emailLoading ? (
            <LoadingAnimation />
          ) : (
            <>
              <div className="flex w-full">
                <span className="flex-1 text-[#010101 text-[22px] font-semibold">
                  Who do you want to send this report for signature to?
                </span>
                <button
                  onClick={() => setSignatureOpen(false)}
                  className="text-[#090909]"
                >
                  <CloseRounded />
                </button>
              </div>
              <div className="flex gap-6 w-full flex-col">
                <span className="font-semibold text-lg">Tenants</span>
                <div className="grid gap-y-8 gap-x-32 grid-cols-3">
                  {tenants?.map((item, index) => (
                    <div
                      className="flex gap-6 items-center min-h-[40px]"
                      key={item.email}
                    >
                      <input
                        type="checkbox"
                        className="w-6 h-6 bg-[#FEFEFF] shadow-sm"
                        checked={selectedTenants.includes(item.email)}
                        onChange={() =>
                          handleCheckboxClick("tenant", item.email)
                        }
                      />
                      <div className="flex flex-col min-w-[250px] flex-grow">
                        <span className="font-normal text-[16px] leading-none">
                          {item.name}
                        </span>
                        {index === 0 && tenants.length === 2 && (
                          <span className="text-[14px] text-gray-500">
                            (Lead Tenant)
                          </span>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="w-full flex  ">
                <button
                  className="text-sm font-medium border bg-coolBlue text-white px-4 rounded-lg shadow-md w-[190px] py-4"
                  onClick={() => handleSendSigature()}
                >
                  Send for Signature
                </button>
              </div>
            </>
          )}
        </div>
      </Modal>
      <ModalComponent
        showArrows={false}
        isModalOpen={open}
        setIsModalOpen={handleClose}
        photos={[currentImage]}
      />

      <AlertDialog
        open={deleteReportDialogOpen}
        handleClose={handleDeleteReportDialogClose}
        accept={deleteDialogAccept}
        content={deleteDialogContent}
      />
    </div>
  );
};

export default Tbl;
